<template>
  <div class="users_table_row" :class="{ 'marked-table': isDeleted }">
    <div class="col-1">{{ firstName + " " + lastName }}</div>
    <div class="col-1">{{ email }}</div>
    <div class="col-3">
      <router-link :to="`/edit-user/${userId}`" v-if="this.$store.getters.adminRole === 'SuperAdmin' && !isDeleted">
        <img src="@/assets/images/edit.svg" alt="edit">
      </router-link>
      <a data-toggle="modals" :data-target="`#modals-delete-${userId}`"
        v-if="this.$store.getters.adminRole === 'SuperAdmin' && !isDeleted" >
        <img src="@/assets/images/trash.svg" alt="delete">
      </a>
      <button class="btn-new-admin" v-if="isDeleted" @click="activateUser" style="cursor: pointer">Activate</button>
    </div>
    <div class="modals modals-delete" :id="`modals-delete-${userId}`">
      <h3>Are You sure?</h3>
      <div class="modal-footer">
        <button class="button close btn-close" data-close="modals">Cancel</button>
        <button class="button btn-exit" @click="deleteUser" data-close="modals">Yes</button>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modals modal_notification" :id="`modal_${userId}`">
    <div class="content_group">
      <img :src="require(`@/assets/images/${modal.selectedIcon}`)" alt="Notification icon">
      <div class="modal_content">
        <span class="not_title">{{ modal.title }}</span>
        <span class="modal_text">{{ modal.body }}</span>
      </div>
    </div>
    <a href="№" class="close btn-close" data-close="modals">⨉</a>
  </div>

  <loading v-model:active="isLoading" :can-cancel="true" :is-full-page="fullPage" color="#26A28F" />
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import userService from '@/services/userService';

export default {
  methods: {
    deleteUser: async function () {
      var self = this;
      var response = await userService.deleteUser(self.userId);
      if (response.data.successful) {
        self.toggleModal(self.modal.successIcon, "Success", "User has been deleted");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
      else {
        self.toggleModal(self.modal.errorIcon, "Something went wrong", response.data.errors[0].messages[0]);
      }
    },
    activateUser: async function () {
      var self = this;
      var response = await userService.activateUser(self.userId);
      if (response.data.successful) {
        self.toggleModal(self.modal.successIcon, "Success", "User has been activated");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
      else {
        self.toggleModal(self.modal.errorIcon, "Something went wrong", response.data.errors[0].messages[0]);
      }
    },
    toggleModal: function (imgSrc, title, body) {
      var self = this;
      self.modal.selectedIcon = imgSrc;
      self.modal.title = title;
      self.modal.body = body;

      var overlay_modals = document.querySelector(".overlay");
      const toast_modal = document.getElementById("modal_" + self.userId);
      toast_modal.classList.add('open');
      overlay_modals.classList.remove("hidden");
      setTimeout(function () {
        toast_modal.classList.remove('open');
        overlay_modals.classList.add("hidden");
      }, 3000);
    }
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      errorMessage: '',
      modal: {
        title: "",
        body: "",
        successIcon: "success_icon.svg",
        warningIcon: "warning_icon.svg",
        errorIcon: "error_icon.svg",
        selectedIcon: "success_icon.svg"
      }
    }
  },
  props: ["userId", "firstName", "lastName", "email", "isDeleted"],
  components: { Loading }
}
</script>
