<template>
  <li class="table-row">
    <div class="col col-1">
      {{ number}}
    </div>
    <div class="col col-2">
      <span>{{ fullName }}</span>
    </div>
    <div class="col col-3">
      <span>{{ phoneNumber }}</span>
    </div>
    <div class="col col-4">
      <span v-for="driverType in driverTypes" :key="driverType.id">{{ driverType.type }}</span>
    </div>
    <div class="col col-5">
      <span>{{ dateToPrettyFormat(new Date(createdDate)) }}</span>
    </div>
    <div class="col col-6">
      <router-link :to="`/driver-info/${id}`" v-if="this.$store.getters.adminRole === 'SuperAdmin' && !isDeleted">
        <img src="@/assets/images/edit.svg" alt="">
      </router-link>
      <a href="№" data-toggle="modals" :data-target="`#modals-delete-${id}`"
        v-if="this.$store.getters.adminRole === 'SuperAdmin' && !isDeleted">
        <img src="@/assets/images/trash.svg" alt="">
      </a>
      <h3 v-if="isDeleted" style="font-size: 20px;" >Deleted</h3>
    </div>
    <div class="modals modals-delete" :id="`modals-delete-${id}`">
      <h3>Are You sure?</h3>
      <div class="modal-footer">
        <button class="button close btn-close" data-close="modals">Cancel</button>
        <button class="button btn-exit" @click="deleteDriver" data-close="modals">Yes</button>
      </div>
    </div>
  </li>

  <!-- Modal -->
  <div class="modals modal_notification" :id="`modal_${id}`">
    <div class="content_group">
      <img :src="require(`@/assets/images/${modal.selectedIcon}`)" alt="Notification icon">
      <div class="modal_content">
        <span class="not_title">{{ modal.title }}</span>
        <span class="modal_text">{{ modal.body }}</span>
      </div>
    </div>
    <a href="№" class="close btn-close" data-close="modals">⨉</a>
  </div>

  <loading v-model:active="isLoading" :can-cancel="true" :is-full-page="fullPage" color="#26A28F" />
</template>

<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import driverService from '@/services/driverService';

export default {
  data() {
    return {
      isLoading: false,
      fullPage: true,
      modal: {
        title: "",
        body: "",
        successIcon: "success_icon.svg",
        warningIcon: "warning_icon.svg",
        errorIcon: "error_icon.svg",
        selectedIcon: "success_icon.svg"
      }
    }
  },
  methods: {
    deleteDriver: async function () {
      var self = this;
      self.isLoading = true;
      var response = await driverService.deleteDriver(self.id);
      self.isLoading = false;

      if (response.data.successful) {
        self.toggleModal(self.modal.successIcon, "Success", "Driver has been deleted");
        window.location.reload();
      }
      else {
        self.toggleModal(self.modal.errorIcon, "Something went wrong", response.data.errors[0].messages[0]);
      }
    },
    dateToPrettyFormat(date) {
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
      let year = date.getFullYear();
      let monthIndex = date.getMonth();
      let day = date.getDate();
      return (day < 10 ? "0" : "") + day + " " + monthNames[monthIndex] + " " + year;
    },
    toggleModal: function (imgSrc, title, body) {
      var self = this;
      self.modal.selectedIcon = imgSrc;
      self.modal.title = title;
      self.modal.body = body;

      var overlay_modals = document.querySelector(".overlay");
      const toast_modal = document.getElementById("modal_" + self.id);
      toast_modal.classList.add('open');
      overlay_modals.classList.remove("hidden");
      setTimeout(function () {
        toast_modal.classList.remove('open');
        overlay_modals.classList.add("hidden");
      }, 3000);
    }
  },
  props: ["id", "fullName", "phoneNumber", "driverTypes", "createdDate", "isReadByAdmin", "isDeleted", "number"],
  components: { Loading }
}
</script>
