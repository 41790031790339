<template>
  <div class="row_right">
    <DashboardTitleComponent title="Edit user" />
    <router-link class="btn-to-users" to="/users">
      <img src="@/assets/images/arrow-left.svg" alt="arrow left"> <span>Back</span>
    </router-link>
    <div class="user_add_main">
      <div class="form_block">
        <div class="row_form">
          <div class="col_form">
            <!-- First name -->
            <label for="user" class="col_title" :class="{ error_class: v$.editUserRequestModel.firstName.$error }">
              Firstname
              <span>*</span>
            </label>
            <input type="text" class="form-control" v-model="editUserRequestModel.firstName"
              @blur="v$.editUserRequestModel.firstName.$touch"
              :class="{ error_border: v$.editUserRequestModel.firstName.$error }">
            <div class="error_class" v-if="v$.editUserRequestModel.firstName.$error">{{
              v$.editUserRequestModel.firstName.$errors[0].$message
            }}</div>
          </div>
          <div class="col_form">
            <!-- Last name -->
            <label for="user" class="col_title" :class="{ error_class: v$.editUserRequestModel.lastName.$error }">
              Lastname
              <span>*</span>
            </label>
            <input type="text" class="form-control" v-model="editUserRequestModel.lastName"
              @blur="v$.editUserRequestModel.lastName.$touch"
              :class="{ error_border: v$.editUserRequestModel.lastName.$error }">
            <div class="error_class" v-if="v$.editUserRequestModel.lastName.$error">{{
              v$.editUserRequestModel.lastName.$errors[0].$message
            }}</div>
          </div>
        </div>
        <div class="row_form">
          <div class="col_form">
            <!-- Email -->
            <label for="user" class="col_title" :class="{ error_class: v$.editUserRequestModel.email.$error }">
              Email
              <span>*</span>
            </label>
            <input type="email" class="form-control" v-model="editUserRequestModel.email"
              @blur="v$.editUserRequestModel.email.$touch"
              :class="{ error_border: v$.editUserRequestModel.email.$error }">
            <div class="error_class" v-if="v$.editUserRequestModel.email.$error">{{
              v$.editUserRequestModel.email.$errors[0].$message
            }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="save_button_field">
      <button class="save-button" :disabled="this.v$.$invalid" :class="{
        'but_green': !this.v$.$invalid
      }" @click="editUser">
        Submit
      </button>
    </div>
  </div>

  <!-- Modal -->
  <div class="modals modal_notification" id="modal">
    <div class="content_group">
      <img :src="require(`@/assets/images/${modal.selectedIcon}`)" alt="Notification icon">
      <div class="modal_content">
        <span class="not_title">{{ modal.title }}</span>
        <span class="modal_text">{{ modal.body }}</span>
      </div>
    </div>
    <a href="№" class="close btn-close" data-close="modals">⨉</a>
  </div>

</template>

<script>

import DashboardTitleComponent from '../shared/dashboardTitleComponent.vue';
import useVuelidate from '@vuelidate/core';
import { required, minLength, maxLength, helpers } from '@vuelidate/validators';
import { validateEmail } from '@/services/validationService';
import userService from '@/services/userService';

export default {
  setup: () => ({
    v$: useVuelidate()
  }),
  mounted() {
    this.getUserById();
  },
  components: {
    DashboardTitleComponent
  },
  methods: {
    getUserById: async function () {
      var self = this;
      var response = await userService.getUserById(self.userId);
      if (response.data.successful) {
        this.editUserRequestModel = response.data.item;
      } else {
        self.toggleModal(self.modal.errorIcon, "Something went wrong", response.data.errors[0].messages[0]);
      }
    },
    editUser: async function () {
      var self = this;
      var response = await userService.editUser(self.editUserRequestModel);
      if (response.data.successful) {
        self.toggleModal(self.modal.successIcon, "Success", "User has been edited");
        setTimeout(function () {
          self.$router.push({
            path: '/users'
          });
        }, 3000);
      } else {
        self.toggleModal(self.modal.errorIcon, "Something went wrong", response.data.errors[0].messages[0]);
      }
    },
    toggleModal: function (imgSrc, title, body) {
      var self = this;
      self.modal.selectedIcon = imgSrc;
      self.modal.title = title;
      self.modal.body = body;

      var overlay_modals = document.querySelector(".overlay");
      const toast_modal = document.getElementById("modal");
      toast_modal.classList.add('open');
      overlay_modals.classList.remove("hidden");
      setTimeout(function () {
        toast_modal.classList.remove('open');
        overlay_modals.classList.add("hidden");
      }, 3000);
    }
  },
  data() {
    return {
      // Request models
      editUserRequestModel: {
        firstName: '',
        lastName: '',
        email: ''
      },
      modal: {
        title: "",
        body: "",
        successIcon: "success_icon.svg",
        warningIcon: "warning_icon.svg",
        errorIcon: "error_icon.svg",
        selectedIcon: "success_icon.svg"
      }
    }
  },
  validations() {
    return {
      editUserRequestModel: {
        firstName: {
          required: helpers.withMessage("First name is required!", required),
          maxLength: maxLength(200),
          minLength: minLength(2)
        },
        lastName: {
          required: helpers.withMessage("Last name is required!", required),
          maxLength: maxLength(200),
          minLength: minLength(2)
        },
        email: {
          required: helpers.withMessage("Email is required!", required),
          validateEmail: helpers.withMessage("Email is invalid!", validateEmail)
        }
      }
    }
  },
  props: [
    'userId'
  ]
}
</script>
