<template>
  <div class="reuq-driver-items">
    <h3>{{ title }}</h3>
    <ul>
      <li v-for="item in items" :key="item">{{ item }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: [
    'title',
    'items'
  ]
}
</script>

<style lang="scss" scoped>

</style>