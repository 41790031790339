<template>
  <div class="row_right">
    <DashboardTitleComponent :title="this.driverDetails.firstName + ' ' + this.driverDetails.lastName" />
    <div class="driver-info-bar">
      <router-link class="btn-to-drivers" to="/drivers">
        <img src="@/assets/images/arrow-left.svg" alt="arrow left"> <span>Back</span>
      </router-link>
      <div class="info_right">
        <router-link class="btn-info-edit" :to="`/edit-driver/${driverId}`"><img src="@/assets/images/edit.svg"
            alt="edit"><span>Edit</span></router-link>
        <a data-toggle="modals" data-target="#modals-delete" class="btn-info-delete" style="cursor: pointer">
          <img src="@/assets/images/trash.svg" alt="trash">
          <span>Remove</span>
        </a>
      </div>
    </div>
    <div class="driver_info">
      <h3>Personal info</h3>
      <div class="driver_contact">
        <div class="info-col-1">
          <span class="header">Fullname</span>
          <span class="text">{{ driverDetails.firstName + ' ' + driverDetails.lastName }}</span>
        </div>
        <div class="info-col-2"><span class="header">Phone number</span>
          <span class="text">{{ driverDetails.phoneNumber }}</span>
        </div>
        <div class="info-col-3"><span class="header">Email</span>
          <span class="text">{{ driverDetails.email }}</span>
        </div>
        <div class="info-col-4"><span class="header">Date of Birth</span>
          <span class="text">{{ dateToPrettyFormat(new Date(driverDetails.dateOfBirth)) }}</span>
        </div>
        <div class="info-col-5"><span class="header">Experience</span>
          <span class="text">{{ driverDetails.experience ? driverDetails.experience.value : '-' }}</span>
        </div>
        <div class="info-col-6"><span class="header">Address</span>
          <span class="text">{{ (driverDetails.address ? driverDetails.address + ', ' : '') + driverDetails.state.name +
            ', ' + driverDetails.zipCode + ', ' + 'Usa'
          }}</span>
        </div>
      </div>
    </div>
    <div class="company-reuqeriment">

      <RequirementItem v-if="driverDetails.driverTypes.length > 0" title="Driver Type"
        :items="driverDetails.driverTypes.map(x => x.type)" />

      <RequirementItem v-if="driverDetails.freightTypes.length > 0" title="Route Type"
        :items="driverDetails.routeTypes.map(x => x.type)" />

      <RequirementItem v-if="driverDetails.routeTypes.length > 0" title="Freight Type"
        :items="driverDetails.freightTypes.map(x => x.type)" />

      <RequirementItem title="Endorsements"
        :items="driverDetails.endorsements.map(x => x.name)" />

    </div>
  </div>
  <div class="modals modals-delete" id="modals-delete">
    <h3>Are You sure?</h3>
    <div class="modal-footer">
      <button class="button close btn-close" data-close="modals">Cancel</button>
      <button class="button btn-exit" data-close="modals" @click="deleteDriver">Yes</button>
    </div>
  </div>
  <!-- Modal -->
  <div class="modals modal_notification" :id="`modal_${id}`">
    <div class="content_group">
      <img :src="require(`@/assets/images/${modal.selectedIcon}`)" alt="Notification icon">
      <div class="modal_content">
        <span class="not_title">{{ modal.title }}</span>
        <span class="modal_text">{{ modal.body }}</span>
      </div>
    </div>
    <a href="№" class="close btn-close" data-close="modals">⨉</a>
  </div>
</template>

<script>

import DashboardTitleComponent from '../shared/dashboardTitleComponent.vue';
import RequirementItem from '../addDriverComponents/reuqDriverInfo.vue';
import 'vue-loading-overlay/dist/css/index.css';
import driverService from '@/services/driverService';

export default {
  mounted() {
    this.getDriverDetails();
  },
  components: { 
    DashboardTitleComponent, 
    RequirementItem
   },
  methods: {
    getDriverDetails: async function () {
      var response = await driverService.getDriverDetails(this.driverId);

      if (response.data.successful) {
        this.driverDetails = response.data.item;

        if(!this.driverDetails.isReadByAdmin) {
          response = await driverService.markDriverRead(this.driverId);
          if(response.data.successful) {
            this.getDriverDetails();
          }
        }
      }
    },
    dateToPrettyFormat(date) {
      const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept',
        'Oct', 'Nov', 'Dec'];

      let year = date.getFullYear();
      let monthIndex = date.getMonth();
      let day = date.getDate();

      return (day < 10 ? '0' : '') + day + ' ' + monthNames[monthIndex] + ' ' + year
    },
    deleteDriver: async function () {
      var self = this;
      var response = await driverService.deleteDriver(this.driverId);
      if (response.data.successful) {
        this.toggleModal(this.modal.successIcon, "Success", "Driver has been deleted");
        setTimeout(function () {
            self.$router.push({
              path: '/drivers'
            });
          }, 3000);
      }
      else {
        this.toggleModal(this.modal.errorIcon, "Something went wrong", response.data.errors[0].messages[0]);
      }
    },
    toggleModal: function (imgSrc, title, body) {
      var self = this;
      self.modal.selectedIcon = imgSrc;
      self.modal.title = title;
      self.modal.body = body;

      var overlay_modals = document.querySelector(".overlay");
      const toast_modal = document.getElementById("modal_" + self.id);
      toast_modal.classList.add('open');
      overlay_modals.classList.remove("hidden");
      setTimeout(function () {
        toast_modal.classList.remove('open');
        overlay_modals.classList.add("hidden");
      }, 3000);
    }
  },
  data() {
    return {
      // Response models
      driverDetails: {
        driverTypes: [],
        freightTypes: [],
        routeTypes: [],
        endorsements: [],
        experience: {},
        state: {}
      },
      modal: {
        title: "",
        body: "",
        successIcon: "success_icon.svg",
        warningIcon: "warning_icon.svg",
        errorIcon: "error_icon.svg",
        selectedIcon: "success_icon.svg"
      }
    }
  },
  props: {
    driverId: {
      type: Number,
      required: true
    }
  }
}

</script>
