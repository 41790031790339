<template>
  <section class="dashboard">
    <div class="container">
      <div class="row">
        <SidebarComponent />
        <CompanyDetailsContent :companyId="companyId" />
      </div>
    </div>
  </section>

  <loading v-model:active="isLoading" :can-cancel="true" :is-full-page="fullPage" color="#26A28F" />
</template>
<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import CompanyDetailsContent from '@/components/companyDetailsComponents/companyDetailsContent.vue';
import SidebarComponent from '@/components/shared/sidebarComponent.vue';

export default {
  mounted() {
    this.isPageLoading();
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
    }
  },
  components: { SidebarComponent, CompanyDetailsContent, Loading },
  props: {
    companyId: {
      required: true
    }
  },
  methods: {
    isPageLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false
      }, 300)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>