import errorHandler from "@/helpers/errorHandler";
import authService from '@/services/authService';
import { Date } from "core-js";

export default {
    getDrivers: async function (requestModel, page, size) {
        var instance = await authService.getAuthorizedRequest();

        var response = {};

        var url = `/api/driver/getDrivers?page=${page}&size=${size}&name=${requestModel.name}&experienceId=${requestModel.experienceId}`;

        requestModel.driverTypeIds.forEach(x => {
            url += `&driverTypeIds=${x}`
        });

        requestModel.freightTypeIds.forEach(x => {
            url += `&freightTypeIds=${x}`
        });

        requestModel.routeTypeIds.forEach(x => {
            url += `&routeTypeIds=${x}`
        });

        requestModel.endorsementIds.forEach(x => {
            url += `&endorsementTypeIds=${x}`
        });

        await instance.get(url)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                response = await errorHandler.handleRequestError(error);
            });

        return response;
    },
    createDriverAsAdmin: async function (createDriverRequestModel) {
        var instance = await authService.getAuthorizedRequest();

        var response = {};
        await instance
            .post("api/driver/CreateDriverAsAdmin", createDriverRequestModel)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                response = await errorHandler.handleRequestError(error);
            });

        return response;
    },
    getDriverDetails: async function (driverId) {
        var instance = await authService.getAuthorizedRequest();

        var response = {};

        await instance.get(`/api/driver/getDriverDetails?driverId=${driverId}`)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                response = await errorHandler.handleRequestError(error);
            });
        return response;
    },
    editDriver: async function (requestModel) {
        var instance = await authService.getAuthorizedRequest();

        var response = {};
        await instance
            .post("api/driver/editDriver", requestModel)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                if (error.response) {
                    response = error.response;
                } else {
                    response = await errorHandler.handleRequestError(error);
                }
            });

        return response;
    },
    deleteDriver: async function (driverId) {
        var instance = await authService.getAuthorizedRequest();

        var response = {};
        await instance
            .post(`api/driver/deleteDriver?driverId=${driverId}`)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                if (error.response) {
                    response = error.response;
                } else {
                    response = await errorHandler.handleRequestError(error);
                }
            });

        return response;
    },
    markDriverRead: async function(driverId) {
        var instance = await authService.getAuthorizedRequest();
        var response = {};

        await instance.post(`/api/driver/markDriverRead?driverId=${driverId}`)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                if(error.response) {
                    response = error.response;
                } else {
                    response = await errorHandler.handleRequestError(error);
                }
            });
        return response;
    },
    getTodaySendedLeadsCount: async function () {
        var instance = await authService.getAuthorizedRequest();

        var response = {};

        await instance.get(`/api/lead/getTodaySendedLeadsCount`)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                response = await errorHandler.handleRequestError(error);
            });
        return response;
    },
    getWeeklyRegisteredDriversCount: async function () {
        var instance = await authService.getAuthorizedRequest();
        var response = {};
        var curr = new Date;
        var date = new Date(curr.setDate(curr.getDate() - curr.getDay() - 7)).toISOString();
        await instance.get(`/api/driver/getRegisteredDriversCountAfter?date=${date}`)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                response = await errorHandler.handleRequestError(error);
            });
        return response;
    },
    getMonthlyRegisteredDriversCount: async function () {
        var instance = await authService.getAuthorizedRequest();
        var response = {};
        var curr = new Date;
        var date = new Date(curr.setDate(curr.getDate() - curr.getDay() - 31)).toISOString();
        await instance.get(`/api/driver/getRegisteredDriversCountAfter?date=${date}`)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                response = await errorHandler.handleRequestError(error);
            });
        return response;
    },
    getDriverRegistrationStatistics: async function (frequency) {
        var instance = await authService.getAuthorizedRequest();
        var response = {};

        await instance.get(`/api/driver/GetDriverRegistrationStatistics?frequency=${frequency}`)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                response = await errorHandler.handleRequestError(error);
            });
        return response;
    }
}