<template>
  <div class="row_right">
    <DashboardTitleComponent title="Advertisements" />
    <div class="adv_table">
      <div class="adv_header">
        <div class="adv_col_1">Company logo and name</div>
        <div class="adv_col_2">Subject</div>
        <div class="adv_col_3">Contact info</div>
        <div class="adv_col_4">Phone number</div>
      </div>
      <AdvTableComponent v-for="advertisement in advertisements" :key="advertisement.vacancyId"
        :logoBase64Encoded="advertisement.logoBase64Encoded" :companyName="advertisement.companyName"
        :subject="advertisement.subject" :contacteeName="advertisement.contacteeName"
        :phoneNumber="advertisement.companyContactPhoneNumber" :advertisementId="advertisement.vacancyId" :status="advertisement.status"/>
    </div>
    <div class="pagination-container"> <vue-awesome-paginate v-if="pagination.advertisementsSuccessfullyLoaded"
        :total-items="pagination.totalCount" v-model="pagination.page" :items-per-page="pagination.size"
        :max-pages-shown="4" paginationContainerClass="pagination" active-page-class="active-page"
        :hide-prev-next-when-ends="true" :on-click="getAdvertisements" paginateButtonsClass="page"
        back-button-class="back-btn" next-button-class="next-btn">
        <template #prev-button>
          <span>
            <img src="@/assets/images/left-pagination.svg" alt="">
          </span>
        </template>
        <template #next-button>
          <span>
            <img src="@/assets/images/right-pagination.svg" height="12" />
          </span>
        </template>
      </vue-awesome-paginate>
    </div>
  </div>
  <loading v-model:active="isLoading" :can-cancel="true" :is-full-page="fullPage" color="#26A28F" />
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import DashboardTitleComponent from '../shared/dashboardTitleComponent.vue';
import AdvTableComponent from './inComponent/advTableComponent.vue';

// services
import vacancyService from '@/services/vacancyService';

export default {
  mounted() {
    this.getAdvertisements();
  },
  components: { DashboardTitleComponent, AdvTableComponent, Loading },
  methods: {
    isPageLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false
      }, 300)
    },
    getAdvertisements: async function () {

      var self = this;
      self.isPageLoading();
      var response = await vacancyService.getAdvertisements(self.pagination.page, self.pagination.size)

      if (response.data.successful) {
        this.advertisements = response.data.items;

        if (this.advertisements.length > 0) {
          this.pagination.advertisementsSuccessfullyLoaded = true;
          this.pagination.totalCount = response.data.totalCount;
        } else {
          this.pagination.advertisementsSuccessfullyLoaded = false;
          this.pagination.totalCount = 0;
        }
      } else {
        this.pagination.advertisementsSuccessfullyLoaded = false;
        this.pagination.totalCount = 0;
      }
    }
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      // Response models
      advertisements: [],
      pagination: {
        page: 1,
        totalCount: 0,
        size: 5,
        advertisementsSuccessfullyLoaded: false
      }
    }
  },

}
</script>

<style lang="scss" scoped>

</style>