<template>
  <div class="reuq-items">
    <h3>{{ requirementTitle }}</h3>
    <ul>
      <li v-for="item in requirementItems" :key="item">{{ item }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: [
    'requirementTitle',
    'requirementItems'
  ]
}
</script>

<style lang="scss" scoped>

</style>