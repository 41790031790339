<template>
  <div class="row_right">
    <DashboardTitleComponent title="Create admin" />
    <router-link class="btn-to-users" to="/users">
      <img src="@/assets/images/arrow-left.svg" alt="arrow left"> <span>Back</span>
    </router-link>
    <div class="user_add_main">
      <div class="form_block">
        <div class="row_form">
          <div class="col_form">
            <!-- First name -->
            <label for="user" class="col_title" :class="{ error_class: v$.createAdminRequestModel.firstName.$error }">
              Firstname
              <span>*</span>
            </label>
            <input type="text" class="form-control" placeholder="Ernest" v-model="createAdminRequestModel.firstName"
              @blur="v$.createAdminRequestModel.firstName.$touch"
              :class="{ error_border: v$.createAdminRequestModel.firstName.$error }">
            <div class="error_class" v-if="v$.createAdminRequestModel.firstName.$error">{{
              v$.createAdminRequestModel.firstName.$errors[0].$message
            }}</div>
          </div>
          <div class="col_form">
            <!-- Last name -->
            <label for="user" class="col_title" :class="{ error_class: v$.createAdminRequestModel.lastName.$error }">
              Lastname
              <span>*</span>
            </label>
            <input type="text" class="form-control" placeholder="Vinson" v-model="createAdminRequestModel.lastName"
              @blur="v$.createAdminRequestModel.lastName.$touch"
              :class="{ error_border: v$.createAdminRequestModel.lastName.$error }">
            <div class="error_class" v-if="v$.createAdminRequestModel.lastName.$error">{{
              v$.createAdminRequestModel.lastName.$errors[0].$message
            }}</div>
          </div>
        </div>
        <div class="row_form">
          <div class="col_form">
            <!-- Email -->
            <label for="user" class="col_title" :class="{ error_class: v$.createAdminRequestModel.email.$error }">
              Email
              <span>*</span>
            </label>
            <input type="email" class="form-control" placeholder="ernest_vinson@gmail.com"
              v-model="createAdminRequestModel.email" @blur="v$.createAdminRequestModel.email.$touch"
              :class="{ error_border: v$.createAdminRequestModel.email.$error }">
            <div class="error_class" v-if="v$.createAdminRequestModel.email.$error">{{
              v$.createAdminRequestModel.email.$errors[0].$message
            }}</div>
          </div>
          <div class="col_form">
            <!-- Password -->
            <label for="user" class="col_title" :class="{ error_class: v$.createAdminRequestModel.password.$error }">
              Password
              <span>*</span>
            </label>
            <input type="password" class="form-control" placeholder="••••••••••••••••"
              v-model="createAdminRequestModel.password" @blur="v$.createAdminRequestModel.password.$touch"
              :class="{ error_border: v$.createAdminRequestModel.password.$error }">
            <div class="error_class" v-if="v$.createAdminRequestModel.password.$error">{{
              v$.createAdminRequestModel.password.$errors[0].$message
            }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="save_button_field">
      <button class="save-button" :disabled="this.v$.$invalid" :class="{
        'but_green': !this.v$.$invalid
      }" @click="createAdmin">
        Save
      </button>
    </div>
  </div>

  <!-- Modal -->
  <div class="modals modal_notification" id="modal">
    <div class="content_group">
      <img :src="require(`@/assets/images/${modal.selectedIcon}`)" alt="Notification icon">
      <div class="modal_content">
        <span class="not_title">{{ modal.title }}</span>
        <span class="modal_text">{{ modal.body }}</span>
      </div>
    </div>
    <a href="№" class="close btn-close" data-close="modals">⨉</a>
  </div>

  <loading v-model:active="isLoading" :can-cancel="true" :is-full-page="fullPage" color="#26A28F" />
</template>

<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import DashboardTitleComponent from '../shared/dashboardTitleComponent.vue';
import useVuelidate from '@vuelidate/core';
import { required, minLength, maxLength, helpers } from '@vuelidate/validators';
import { validateEmail } from '@/services/validationService';
import userService from '@/services/userService';

export default {
  setup: () => ({
    v$: useVuelidate()
  }),
  components: {
    DashboardTitleComponent,
    Loading
  },
  methods: {
    createAdmin: async function () {
      var self = this;
      self.isLoading = true;
      var response = await userService.createAdmin(self.createAdminRequestModel);
      self.isLoading = false;

      if (response.data.successful) {
        self.toggleModal(self.modal.successIcon, "Success", "Admin has been created.");
        setTimeout(function () {
          self.$router.push({
            path: '/users'
          });
        }, 3000);
      }
      else {
        self.toggleModal(self.modal.errorIcon, "Something went wrong", response.data.errors[0].messages[0]);
      }
    },
    toggleModal: function (imgSrc, title, body) {
      var self = this;
      self.modal.selectedIcon = imgSrc;
      self.modal.title = title;
      self.modal.body = body;

      var overlay_modals = document.querySelector(".overlay");
      const toast_modal = document.getElementById("modal");
      toast_modal.classList.add('open');
      overlay_modals.classList.remove("hidden");
      setTimeout(function () {
        toast_modal.classList.remove('open');
        overlay_modals.classList.add("hidden");
      }, 3000);
    }
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      // Request models
      createAdminRequestModel: {
        firstName: '',
        lastName: '',
        email: '',
        password: ''
      },
      modal: {
        title: "",
        body: "",
        successIcon: "success_icon.svg",
        warningIcon: "warning_icon.svg",
        errorIcon: "error_icon.svg",
        selectedIcon: "success_icon.svg"
      }
    }
  },
  validations() {
    return {
      createAdminRequestModel: {
        firstName: {
          required: helpers.withMessage("First name is required!", required),
          maxLength: maxLength(200),
          minLength: minLength(2)
        },
        lastName: {
          required: helpers.withMessage("Last name is required!", required),
          maxLength: maxLength(200),
          minLength: minLength(2)
        },
        email: {
          required: helpers.withMessage("Email is required!", required),
          validateEmail: helpers.withMessage("Email is invalid!", validateEmail)
        },
        password: {
          required: helpers.withMessage("Password is required!", required),
          maxLength: maxLength(200),
          minLength: minLength(8)
        }
      }
    }
  }
}
</script>
