let config;
//Config file for configuration it includes config params
if (process.env.NODE_ENV === "production") {
  config = {
    baseApiUrl: "https://api.findcdl.com",
    perPage: 20,
    rounderPoint: 100,
    salt: 10,
    pingPeriod: 30000
  };
} else {
  config = {
    baseApiUrl: "https://localhost:8081",
    perPage: 20,
    rounderPoint: 100,
    salt: 10,
    pingPeriod: 10000
  };
}

export { config };