<template>
  <div class="row_right">
    <DashboardTitleComponent title="New Requests" />
    <router-link class="btn-to-users" to="/requests">
      <img src="@/assets/images/arrow-left.svg" alt="arrow left"> <span>Back</span>
    </router-link>
    <div class="req_info">
      <div class="req_company">
        <h3>Company info</h3>
        <span>{{ companyRequest.receivedOn }}</span>
      </div>
      <div class="req_data">
        <div class="req_data_header">
          <div class="col-1">Company name</div>
          <div class="col-1">Contacter name</div>
          <div class="col-2">Email</div>
          <div class="col-1">Phone</div>
        </div>
        <div class="req_data_row">
          <div class="col-1">{{ companyRequest.companyName }}</div>
          <div class="col-1">{{ companyRequest.contacteeName }}</div>
          <div class="col-2"><a :href="`mailto:${companyRequest.email}`">{{ companyRequest.email }}</a></div>
          <div class="col-1">{{ companyRequest.phoneNumber }}</div>

        </div>
        <div class="req_data_header">
          <div class="col-3">Subject</div>
        </div>
        <div class="req_data_row">
          <div class="col-3">{{ companyRequest.subject }}</div>
        </div>
      </div>

      <div class="doneButton">
        <a :href="`mailto:${companyRequest.email}`" class="mail" v-if="!companyRequest.isEmailSentBack">
        <img src="@/assets/images/mail.svg" alt="mail">
        Send email
        </a>
        <a class="mail" @click="setDoneEmailSentBack" v-if="!companyRequest.isEmailSentBack" style="cursor: pointer">Done</a>
      </div>
      
    </div>
  </div>
</template>

<script>
import DashboardTitleComponent from '../shared/dashboardTitleComponent.vue';

// services
import companyService from '@/services/companyService';

export default {
  mounted() {
    this.getCompanyRequestById();
  },
  components: { DashboardTitleComponent },
  methods: {
    getCompanyRequestById: async function () {
      var self = this;
      var response = await companyService.getCompanyRequestById(self.companyRequestId);

      if (response.data.successful) {
        this.companyRequest = response.data.item;

        if (!this.companyRequest.isReadByAdmin) {
          response = await companyService.markCopmanyRequestAsRead(self.companyRequest.id);

          if (response.data.successful) {
            this.companyRequest.isReadByAdmin = true;
          }
        }
      }
    },
    setDoneEmailSentBack: async function () {
      var self = this;
      var response = await companyService.setDoneEmailSentBack(self.companyRequestId);

      if (response.data.successful) {
        window.location.reload();
      } 
    }
  },
  data() {
    return {
      companyRequest: {}
    }
  },
  props: {
    companyRequestId: {
      type: Number,
      required: true
    }
  }
}
</script>
