<template>
  <div class="row_right">
    <DashboardTitleComponent title="Companies" />
    <div class="tab">
      <div class="tab_buttons">
        <button class="tablinks" @click="openTabs($event, 'all')" id="defaultOpen">All</button>
        <button class="tablinks" @click="openTabs($event, 'basic')">Basic</button>
        <button class="tablinks" @click="openTabs($event, 'standard')">Standard</button>
        <button class="tablinks" @click="openTabs($event, 'premium')">Premium</button>
      </div>

      <div class="search">
        <input type="text" placeholder="Search by name" class="search-field"
          v-model="getCompaniesRequestModel.companyName" />
        <button type="button" class="search-button" @click="getCompaniesViaFindButton">
          <img src="@/assets/images/search.svg">
        </button>
        <router-link class="add-company" to="/add-company">Add company</router-link>
      </div>

    </div>
    <ul class="responsive-table companies-table">
      <li class="table-header">
        <div class="col col-1">Company logo and name</div>
        <div class="col col-2">Subscription</div>
        <div class="col col-3">Contact info</div>
        <div class="col col-4">Phone number</div>
        <div class="col col-5"> &nbsp;</div>
      </li>

      <div id="all">
        <!-- All Plan Members -->
        <CompanyPlanComponent v-for="company in companies" :key="company.companyId" :company="company.companyName"
          :logoBase64Encoded="company.logoBase64Encoded" :plan="company.subscription" :name="company.contacteeName"
          :phoneNumber="company.phoneNumber" :companyId="company.companyId" :is-deleted="company.isDeleted"/>
      </div>

      <div id="basic">

      </div>

      <div id="standard">
      </div>

      <div id="premium">
      </div>
    </ul>
    <div class="pagination-container">
      <!-- Pagination -->
      <vue-awesome-paginate v-if="companiesSuccessfullyLoaded" :total-items="paginationData.totalCount"
        v-model="paginationData.page" :items-per-page="paginationData.size" :max-pages-shown="4"
        paginationContainerClass="pagination" active-page-class="active-page" :hide-prev-next-when-ends="true"
        :on-click="getCompaniesForPage" paginateButtonsClass="page" back-button-class="back-btn"
        next-button-class="next-btn">
        <template #prev-button>
          <span>
            <img src="@/assets/images/left-pagination.svg" height="12" />
          </span>
        </template>
        <template #next-button>
          <span>
            <img src="@/assets/images/right-pagination.svg" height="12" />
          </span>
        </template>
      </vue-awesome-paginate>
    </div>
  </div>

  <loading v-model:active="isLoading" :can-cancel="true" :is-full-page="fullPage" color="#26A28F" />
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import DashboardTitleComponent from '../shared/dashboardTitleComponent.vue';
import CompanyPlanComponent from './inComponent/companyPlanComponent.vue'

import { basicPlanName, silverPlanName, premiumPlanName } from '@/constants/index';

// services
import companyService from '@/services/companyService';

export default {
  mounted() {
    var self = this;
    addEventListener('keypress', function (e) {
      if (e.key === "Enter") {
        self.getCompaniesViaFindButton();
      }
    });

    document.getElementById("defaultOpen").click();
  },
  components: { DashboardTitleComponent, CompanyPlanComponent, Loading },
  methods: {
    isPageLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false
      }, 300)
    },
    openTabs: function (evt, trafic) {
      var i, tablinks;


      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace("active", "");
      }
      document.getElementById(trafic).style.display = "block";
      evt.currentTarget.className += " active";

      switch (trafic) {
        case "basic":
          this.getCompaniesRequestModel.subscriptionPlan = basicPlanName;
          break;
        case "standard":
          this.getCompaniesRequestModel.subscriptionPlan = silverPlanName;
          break;
        case "premium":
          this.getCompaniesRequestModel.subscriptionPlan = premiumPlanName;
          break;
        default:
          this.getCompaniesRequestModel.subscriptionPlan = null;
      }
      this.getCompaniesViaFindButton();
    },
    getCompanies: async function () {
      var response = await companyService.getCompanies(this.getCompaniesRequestModel, this.paginationData.page, this.paginationData.size);
      if (response.data.successful) {
        this.companies = response.data.items;

        if (this.companies.length > 0) {
          this.paginationData.totalCount = response.data.totalCount;
          this.companiesSuccessfullyLoaded = true;
        } else {
          this.companiesSuccessfullyLoaded = false;
        }
      }
    },
    getCompaniesViaFindButton: function () {
      this.isPageLoading();
      this.paginationData.page = 1;
      this.getCompanies();
    },
    getCompaniesForPage(pageNumber) {
      this.isPageLoading();
      this.paginationData.page = pageNumber;
      this.getCompanies();
    }
  },
  computed: {
    basicPlanCompanies() {
      return this.companies.filter(x => x.subscription === basicPlanName);
    },
    silverPlanCompanies() {
      return this.companies.filter(x => x.subscription === silverPlanName);
    },
    premiumPlanCompanies() {
      return this.companies.filter(x => x.subscription === premiumPlanName);
    }
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      // Request models
      getCompaniesRequestModel: {
        companyName: "",
        subscriptionPlan: null
      },
      // Response models
      companiesSuccessfullyLoaded: false,
      paginationData: {
        page: 1,
        totalCount: 0,
        size: 12
      },
      companies: []
    }
  },
}
</script>

<style >

</style>