<template>
  <div class="row_right">
    <DashboardTitleComponent title="Profile" />
    <div class="profile_form">
      <div class="image_row">
        <img
          :src="(selectedProfilePictureBase64Encoded ? selectedProfilePictureBase64Encoded : profileData.profilePictureBase64Encoded)"
          alt="logo" class="image_row"
          v-if="selectedProfilePictureBase64Encoded || profileData.profilePictureBase64Encoded">
        <div class="button_row">

          <!-- Profile picture -->
          <label for="profilePicture" class="button">New Photo</label>
          <input id="profilePicture" type="file" name="profilePicture" @change="processSelectedProfilePicture"
            style="display: none;">

          <!-- Remove profile picture -->
          <a href="#" class="button" @click="removeProfilePicture"
            v-if="profileData.profilePictureBase64Encoded">Remove</a>
        </div>
      </div>
      <div class="form_row">
        <!-- First name -->
        <div class="col_form">
          <label for="name" :class="{ error_class: v$.profileData.firstName.$error }">
            First name
            <span>*</span>
          </label>
          <input type="text" class="form-control" v-model="profileData.firstName"
            @blur="v$.profileData.firstName.$touch" :class="{ error_border: v$.profileData.firstName.$error }">
          <div class="error_class" v-if="v$.profileData.firstName.$error">{{
            v$.profileData.firstName.$errors[0].$message
          }}</div>
        </div>
        <!-- Last name -->
        <div class="col_form">
          <label for="name" :class="{ error_class: v$.profileData.lastName.$error }">
            Last Name
            <span>*</span>
          </label>
          <input type="text" class="form-control" v-model="profileData.lastName" @blur="v$.profileData.lastName.$touch"
            :class="{ error_border: v$.profileData.lastName.$error }">
          <div class="error_class" v-if="v$.profileData.lastName.$error">{{
            v$.profileData.lastName.$errors[0].$message
          }}</div>
        </div>
        <div class="submit_row">
          <!-- Submit -->
          <a @click="updateProfile" class="button" v-if="!this.v$.$invalid" style="cursor: pointer;">
            Submit
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modals modal_notification" id="modal">
    <div class="content_group">
      <img :src="require(`@/assets/images/${modal.selectedIcon}`)" alt="Notification icon">
      <div class="modal_content">
        <span class="not_title">{{ modal.title }}</span>
        <span class="modal_text">{{ modal.body }}</span>
      </div>
    </div>
    <a href="№" class="close btn-close" data-close="modals">⨉</a>
  </div>

  <loading v-model:active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage" />
</template>

<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import DashboardTitleComponent from '../shared/dashboardTitleComponent.vue';
import userService from '@/services/userService';
import useVuelidate from '@vuelidate/core';
import { required, minLength, maxLength, helpers } from '@vuelidate/validators';
import { imageFileAllowedExtensions } from '@/constants/index';

export default {
  setup: () => ({ v$: useVuelidate() }),
  mounted() {
    this.getProfileData();
  },
  components: { 
    DashboardTitleComponent, 
    Loading 
  },
  methods: {
    getProfileData: async function () {
      var self = this;
      self.isLoading = true;
      var response = await userService.getCurrentUserProfileData();
      self.isLoading = false;
      if (response.data.successful) {
        self.profileData = response.data.item;
        if (self.profileData.profilePictureBase64Encoded) {
          self.$store.commit('setProfilePicture', {
            profilePictureBase64Encoded: response.data.item.profilePictureBase64Encoded
          });
        } else {
          self.$store.commit('setProfilePicture', {
            profilePictureBase64Encoded: null
          });
        }
      } else {
        self.toggleModal(self.modal.errorIcon, "Something went wrong", response.data.errors[0].messages[0]);
      }
    },
    removeProfilePicture: async function () {
      this.isLoading = true;
      var response = await userService.deleteCurrenUserProfilePicture();
      this.isLoading = false;
      if (response.data.successful) {
        this.$router.go();
        this.getProfileData();
      } else {
        this.toggleModal(self.modal.errorIcon, "Something went wrong", response.data.errors[0].messages[0]);
      }
    },
    processSelectedProfilePicture: async function (e) {
      var self = this;
      if (!e.target.files[0]) {
        return;
      }

      const file = e.target.files[0];
      let extension = file.name.split(".").pop().toLowerCase();
      if (!imageFileAllowedExtensions.some(x => x.toLowerCase() === extension)) {
        self.toggleModal(self.modal.errorIcon, "Something went wrong", "Invalid extension");
        e.target.value = e.target.defaultValue;
        return;
      }

      if (file.size <= 0) {
        self.toggleModal(self.modal.errorIcon, "Something went wrong", "Invalid size");
        return;
      }

      try {
        let base64Encoded = await this.convertFileToBase64Encoded(file);
        this.selectedProfilePictureBase64Encoded = base64Encoded;
      }
      catch (error) {
        self.errorMessage = error;
        self.toggleModal(self.modal.errorIcon, "Something went wrong", "Unknown error happened");
      }
    },
    convertFileToBase64Encoded: async function (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    updateProfile: async function () {
      var self = this;
      const formData = new FormData();
      const profilePicture = document.getElementById("profilePicture").files[0];

      if (profilePicture) {
        formData.append("profilePicture", profilePicture);
      }
      formData.append("firstName", this.profileData.firstName);
      formData.append("lastName", this.profileData.lastName);

      this.isLoading = true;
      var response = await userService.updateProfile(formData);
      this.isLoading = false;
      if (response.data.successful) {
        this.getProfileData();
        self.toggleModal(this.modal.successIcon, "Success", "Profile has been updated");
        this.selectedProfilePictureBase64Encoded = '';
      }
    },
    toggleModal: function (imgSrc, title, body) {
      var self = this;
      self.modal.selectedIcon = imgSrc;
      self.modal.title = title;
      self.modal.body = body;

      var overlay_modals = document.querySelector(".overlay");
      const toast_modal = document.getElementById("modal");
      toast_modal.classList.add('open');
      overlay_modals.classList.remove("hidden");
      setTimeout(function () {
        toast_modal.classList.remove('open');
        overlay_modals.classList.add("hidden");
      }, 3000);
    }
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      errorMessage: "",
      profileData: {
        firstName: '',
        lastName: '',
        profilePictureBase64Encoded: ''
      },
      selectedProfilePictureBase64Encoded: '',
      modal: {
        title: "",
        body: "",
        successIcon: "success_icon.svg",
        warningIcon: "warning_icon.svg",
        errorIcon: "error_icon.svg",
        selectedIcon: "success_icon.svg"
      }
    }
  },
  validations() {
    return {
      profileData: {
        firstName: {
          required: helpers.withMessage("First name is required!", required),
          maxLength: maxLength(200),
          minLength: minLength(2)
        },
        lastName: {
          required: helpers.withMessage("Last name is required!", required),
          maxLength: maxLength(200),
          minLength: minLength(2)
        }
      }
    }
  }
}
</script>
