<template>
  <div class="row_right">
    <DashboardTitleComponent title="Dashboard" />
    <div class="members">
      <MembersComponent
        v-for="plan in subscriptionPlans"
        :key="plan.id"
        :title="plan.title"
        :companiesCount="plan.companiesCount"
        :imgSrc="plan.image"
      />
    </div>
    <div class="table_charts">
      <StaticComponent name="Company statistics" chart="chart_first" />
      <StaticComponent name="Drivers statistics" chart="chart_second" />
    </div>
    <LeadsComponet :leadsRate="allLeadsPerDay" />
  </div>
</template>

<script>
import DashboardTitleComponent from '../shared/dashboardTitleComponent.vue';
import MembersComponent from './inComponent/membersComponent.vue';
import LeadsComponet from './inComponent/leadsComponent.vue'
import StaticComponent from './inComponent/staticComponent.vue';

import companyService from '@/services/companyService';
import driverService from '@/services/driverService';
export default {
  components: { 
    DashboardTitleComponent, 
    MembersComponent, 
    StaticComponent, 
    LeadsComponet 
  },
  mounted() {
    this.getMembersCount();
    this.getTodaySendedLeadsCount();
  },
  methods: {
    getMembersCount: async function () {
      var response = await companyService.getMembersCount();
      if (response.data.successful) {
        for(let i = 0; i < response.data.items.length; i++) {
          let member = this.subscriptionPlans.find(x => x.name === response.data.items[i].name);
          member.companiesCount = response.data.items[i].count;
        }
      }
    },
    getTodaySendedLeadsCount: async function () {
      var response = await driverService.getTodaySendedLeadsCount();
      if (response.data.successful) {
        this.allLeadsPerDay = response.data.item;
      }
    },
  },
  data() {
    return {
      allLeadsPerDay: 0,
      subscriptionPlans: [
        {
          id: 1,
          name: "Basic",
          image: "basic.svg",
          title: "Basic members",
          companiesCount: "0"
        },
        {
          id: 2,
          name: "Standard",
          image: 'silver.svg',
          title: "Standard members",
          companiesCount: "0"
        },
        {
          id: 3,
          name: "Premium",
          image: 'premium.svg',
          title: "Premium members",
          companiesCount: "0"
        }
      ],
    }
  }
}
</script>
