<template>
  <section class="login">
    <div class="container">
      <div class="row">
        <div class="row_left">
          <a href="/" class="logo">
            <img src="@/assets/images/logo.svg" alt="Logo FindCDL" />
          </a>
          <div class="form_block">
            <div class="row_form">
              <span class="with_border_text">Sign in</span>
            </div>

            <!-- Email -->
            <div class="row_form">
              <div class="col_form">
                <label
                  class="form-label"
                  :class="{ error_class: v$.loginRequestModel.email.$error }"
                >
                  Email
                  <span>*</span>
                </label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="admin@findcdl.com"
                  v-model="loginRequestModel.email"
                  @blur="v$.loginRequestModel.email.$touch"
                  :class="{ error_border: v$.loginRequestModel.email.$error }"
                />
                <div
                  class="error_class"
                  v-if="v$.loginRequestModel.email.$error"
                >
                  {{ v$.loginRequestModel.email.$errors[0].$message }}
                </div>
              </div>
            </div>

            <!-- Password -->
            <div class="row_form">
              <div class="col_form password">
                <label
                  class="form-label"
                  :class="{ error_class: v$.loginRequestModel.password.$error }"
                >
                  Password
                  <span>*</span>
                </label>
                <div class="password_field">
                  <input
                    :type="inputType"
                    class="form-control"
                    placeholder="••••••••••••••••"
                    v-model="loginRequestModel.password"
                    @blur="v$.loginRequestModel.password.$touch"
                    :class="{
                      error_border: v$.loginRequestModel.password.$error,
                    }"
                  />
                  <a href="#" @click="passwordSee" class="password_eye"
                    ><img src="@/assets/images/eye.svg" alt=""
                  /></a>
                </div>
                <div
                  class="error_class"
                  v-if="v$.loginRequestModel.password.$error"
                >
                  {{ v$.loginRequestModel.password.$errors[0].$message }}
                </div>
              </div>
            </div>

            <div class="row_bottom">
              <a
                href=""
                class="bottom_link"
                data-toggle="modals"
                data-target="#modals-forget"
                >Forgot password?</a
              >
              <button class="button but_green" @click="loginSuperAdmin">
                Continue
              </button>
            </div>
          </div>
        </div>
        <div class="row_right">
          <h1 class="banner_text">Welcome</h1>
          <span class="banner_innertext"
            >This dashboard belongs to super admin findcdl.com</span
          >
        </div>
      </div>
    </div>
  </section>
  <div class="overlay hidden"></div>

  <!-- FORGET PASSWORD MODAL -->
  <div class="modals modal_forget" id="modals-forget">
    <div class="modal_top">
      <h3>Forget Password</h3>
      <a href="" class="close btn-close" data-close="modals">⨉</a>
    </div>
    <div class="modal_forget_content">
      <!-- Email -->
      <label :class="{ error_class: v$.forgotPassword.email.$error }">
        Enter the email address you forgot the password for
      </label>
      <input
        type="email"
        placeholder="admin@findcdl.com"
        class="form-control"
        v-model="forgotPassword.email"
        @blur="v$.forgotPassword.email.$touch"
        :class="{ error_border: v$.forgotPassword.email.$error }"
      />
      <div class="error_class" v-if="v$.forgotPassword.email.$error">
        {{ v$.forgotPassword.email.$errors[0].$message }}
      </div>
      <div class="modal_footer">
        <button
          class="button but_green"
          @click="forgetPassword"
          style="cursor: pointer"
        >
          Continue
        </button>
      </div>
    </div>
  </div>

  <!-- SECOND MODAL -->
  <div class="modals modal_forget" id="modals-forget-second">
    <div class="modal_top">
      <h3>Forget Password</h3>
      <a href="" class="close btn-close" data-close="modals">⨉</a>
    </div>
    <div class="modal_forget_content">
      <!-- Verification code -->
      <label :class="{ error_class: v$.forgotPassword.code.$error }">
        Enter recevied code
      </label>
      <input
        type="text"
        placeholder="123456"
        class="form-control"
        v-model="forgotPassword.code"
        @blur="v$.forgotPassword.code.$touch"
        :class="{ error_border: v$.forgotPassword.code.$error }"
      />

      <!-- New password -->
      <label :class="{ error_class: v$.forgotPassword.password.$error }">
        Enter new password
      </label>
      <input
        type="password"
        placeholder="Enter new password"
        class="form-control"
        v-model="forgotPassword.password"
        @blur="v$.forgotPassword.password.$touch"
        :class="{ error_border: v$.forgotPassword.password.$error }"
      />

      <!-- New password -->
      <label :class="{ error_class: v$.forgotPassword.passwordConfirm.$error }">
        Confirm password
      </label>
      <input
        type="password"
        placeholder="Enter password confirm"
        class="form-control"
        v-model="forgotPassword.passwordConfirm"
        @blur="v$.forgotPassword.passwordConfirm.$touch"
        :class="{ error_border: v$.forgotPassword.passwordConfirm.$error }"
      />

      <div class="modal_footer">
        <button class="button but_green close btn-close" @click="resetPassword">
          Continue
        </button>
      </div>
    </div>
  </div>

  <div class="modals modal_notification" id="modal">
    <div class="content_group">
      <img
        :src="require(`@/assets/images/${modal.selectedIcon}`)"
        alt="Notification icon"
      />
      <div class="modal_content">
        <span class="not_title">{{ modal.title }}</span>
        <span class="modal_text">{{ modal.body }}</span>
      </div>
    </div>
    <a href="№" class="close btn-close" data-close="modals">⨉</a>
  </div>

  <loading
    v-model:active="isLoading"
    :can-cancel="true"
    :is-full-page="fullPage"
    color="#26A28F"
  />
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import authService from "@/services/authService";
import {
  adminRoleName,
  idClaimName,
  emailClaimName,
  phoneNumberClaimName,
  roleClaimName,
  jwtTokenExpClaimName,
  superAdminRoleName,
  profilePictureClaimName,
} from "@/constants/index";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { validateEmail } from "@/services/validationService";

export default {
  setup: () => ({
    v$: useValidate(),
  }),
  mounted() {
    this.init();
  },
  methods: {
    init: function () {
      document.addEventListener("click", function (e) {
        const overlay_modals = document.querySelector(".overlay");
        let $target = e.target;

        if ($target.closest('[data-toggle="modals"]')) {
          e.preventDefault();
          $target = $target.closest('[data-toggle="modals"]');
          document.querySelector($target.dataset.target).classList.add("open");
          overlay_modals.setAttribute(
            "data-closeoverlay",
            $target.dataset.target
          );
          overlay_modals.classList.remove("hidden");
        } else if ($target.dataset.close === "modals") {
          e.preventDefault();
          $target.closest(".modals").classList.remove("open");
          overlay_modals.classList.add("hidden");
        } else if ($target.dataset.closeoverlay != null) {
          e.preventDefault();
          document
            .querySelector($target.dataset.closeoverlay)
            .classList.remove("open");
          overlay_modals.classList.add("hidden");
          $target.dataset.closeoverlay = null;
        }
      });

      if (this.isAuthenticated()) {
        this.$router.push("/dashboard");
      }
    },
    passwordSee() {
      if (this.inputType == "password") {
        this.inputType = "text";
      } else this.inputType = "password";
    },
    loginSuperAdmin: async function () {
      if (!this.loginRequestModel.email || !this.loginRequestModel.password) {
        return;
      }

      this.isLoading = true;
      var response = await authService.loginSuperAdmin(this.loginRequestModel);
      this.isLoading = false;
      if (response.data.successful) {
        var accessToken = response.data.item;
        var decodedJwtToken = authService.parseJwtToken(accessToken);
        var accessTokenExpiry = decodedJwtToken[jwtTokenExpClaimName];
        var id = decodedJwtToken[idClaimName];
        var email = decodedJwtToken[emailClaimName];
        var phoneNumber = decodedJwtToken[phoneNumberClaimName];
        var role = decodedJwtToken[roleClaimName];
        var profilePictureBase64Encoded =
          decodedJwtToken[profilePictureClaimName];

        if (role == adminRoleName || role == superAdminRoleName) {
          this.$store.commit("authenticateSuperAdmin", {
            adminAuthData: {
              accessToken,
              accessTokenExpiry,
              id,
              email,
              phoneNumber,
              role,
              profilePictureBase64Encoded,
            },
          });
          this.$router.push("/dashboard");
        } else {
          this.toggleModal(
            this.modal.errorIcon,
            "Something went wrong",
            "Invalid credentials"
          );
        }
      } else {
        this.toggleModal(
          this.modal.errorIcon,
          "Something went wrong",
          response.data.errors[0].messages[0]
        );
      }
    },
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated;
    },
    forgetPassword: async function () {
      if (!this.forgotPassword.email) {
        return;
      }

      this.closeModal("modals-forget");
      var response = await authService.forgotPasword({
        email: this.forgotPassword.email,
      });
      if (response.data.successful) {
        this.openModal("modals-forget-second");
      } else {
        this.toggleModal(
          this.modal.errorIcon,
          "Something went wrong",
          response.data.errors[0].messages[0]
        );
      }
    },
    resetPassword: async function () {
      if (
        !this.forgotPassword.code ||
        !this.forgotPassword.password ||
        !this.forgotPassword.passwordConfirm
      ) {
        return;
      }

      this.closeModal("modals-forget-second");
      var response = await authService.resetPassword({
        email: this.forgotPassword.email,
        verificationCode: this.forgotPassword.code,
        password: this.forgotPassword.password,
        passwordConfirm: this.forgotPassword.passwordConfirm,
      });

      if (response.data.successful) {
        this.toggleModal(
          this.modal.successIcon,
          "Success",
          "Password has been successfully set"
        );
      } else {
        this.toggleModal(
          this.modal.errorIcon,
          "Something went wrong",
          response.data.errors[0].messages[0]
        );
      }
    },
    toggleModal: function (imgSrc, title, body) {
      this.modal.selectedIcon = imgSrc;
      this.modal.title = title;
      this.modal.body = body;

      var overlay_modals = document.querySelector(".overlay");
      const toast_modal = document.getElementById("modal");
      toast_modal.classList.add("open");
      overlay_modals.classList.remove("hidden");
      setTimeout(function () {
        toast_modal.classList.remove("open");
        overlay_modals.classList.add("hidden");
      }, 3000);
    },
    openModal: function (modalId) {
      var overlay_modals = document.querySelector(".overlay");
      const toast_modal = document.getElementById(modalId);
      toast_modal.classList.add("open");
      overlay_modals.classList.remove("hidden");
    },
    closeModal: function (modalId) {
      var overlay_modals = document.querySelector(".overlay");
      const toast_modal = document.getElementById(modalId);
      toast_modal.classList.remove("open");
      overlay_modals.classList.add("hidden");
    },
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      loginRequestModel: {
        email: null,
        password: null,
      },
      inputType: "password",
      forgotPassword: {
        email: "",
        code: "",
        password: "",
        passwordConfirm: "",
      },
      modal: {
        title: "",
        body: "",
        successIcon: "success_icon.svg",
        warningIcon: "warning_icon.svg",
        errorIcon: "error_icon.svg",
        selectedIcon: "success_icon.svg",
      },
    };
  },
  validations() {
    return {
      loginRequestModel: {
        email: {
          required: helpers.withMessage("Email is required!", required),
          validateEmail: helpers.withMessage("Invalid email!", validateEmail),
        },
        password: {
          required: helpers.withMessage("Password is required!", required),
        },
      },
      forgotPassword: {
        email: {
          required: helpers.withMessage("Email is required", required),
          validateEmail: helpers.withMessage("Email is invalid", validateEmail),
        },
        code: {
          required: helpers.withMessage(
            "Verification code is required",
            required
          ),
        },
        password: {
          required: helpers.withMessage("Password is required", required),
        },
        passwordConfirm: {
          required: helpers.withMessage(
            "Password confirm is required",
            required
          ),
        },
      },
    };
  },
  components: {
    Loading,
  },
};
</script>
