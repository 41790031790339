<template>
  <div class="row_left">

    <router-link to="/dashboard">
      <img class="logo" src="@/assets/images/logo.svg" alt="Logo CDL">
    </router-link>
    <div class="row_menu">
      <router-link class="but_white " to="/dashboard">
        <img src="@/assets/images/home.svg" alt="Dashboard">
        Dashboard <span></span>
      </router-link>

      <router-link class="but_white" to="/companies">
        <img src="@/assets/images/truck.svg" alt="Companies">
        Companies 
        <span class="not_sidebar" v-if="newRequests.companyRequestsCount > 0">
          +{{ newRequests.companyRequestsCount }}
        </span>
      </router-link>

      <router-link class="but_white" to="/drivers">
        <img src="@/assets/images/user.svg" alt="Drivers">
        Drivers 
        <span class="not_sidebar" v-if="newRequests.driversCount > 0">
          +{{ newRequests.driversCount }}
        </span>
      </router-link>

      <router-link class="but_white" to="/advertisments">
        <img src="@/assets/images/bookmark.svg" alt="Bookmarks">
        Advertisements <span></span>
      </router-link>

    </div>
    <div class="row_bottom">
      <a href="#" class="log_out" data-toggle="modals" data-target="#modals-1">
        <span class="out_icon">
          <img src="@/assets/images/log-out.svg" alt="log-out">
        </span>
        <span class="dashboard_out">Log Out</span>
      </a>
    </div>

  </div>
  <div class="modals modal_logout" id="modals-1">
    <h3>Are You sure?</h3>
    <div class="modal-footer">
      <button class="button close btn-close" data-close="modals">Cancel</button>
      <button class="button btn-exit" @click="logoutSuperAdmin()">Yes</button>
    </div>
  </div>
  <div class="overlay hidden"></div>
</template>

<script>
import companyService from '@/services/companyService';

export default {
  mounted() {
    this.getNewRequests();
    document.addEventListener('click', function (e) {
      const overlay_modals = document.querySelector(".overlay");
      let $target = e.target;
      if ($target.closest('[data-toggle="modals"]')) {
        e.preventDefault();
        $target = $target.closest('[data-toggle="modals"]');
        document.querySelector($target.dataset.target).classList.add('open');
        overlay_modals.setAttribute("data-closeoverlay", $target.dataset.target);
        overlay_modals.classList.remove("hidden");
      } else if ($target.dataset.close === 'modals') {
        e.preventDefault();

        $target.closest('.modals').classList.remove('open');

        overlay_modals.classList.add("hidden");
      }
      else if ($target.dataset.closeoverlay != null) {
        e.preventDefault();
        document.querySelector($target.dataset.closeoverlay).classList.remove('open');
        overlay_modals.classList.add("hidden");
        $target.dataset.closeoverlay = null;
      }
    });
  },
  methods: {
    logoutSuperAdmin: function () {
      this.$store.commit('logoutSuperAdmin');
      this.$router.push('/login');
    },
    getNewRequests: async function() {
      var response = await companyService.getNewRequests();
      if(response.data.successful) {
        this.newRequests = response.data.item;
      }
    }
  },
  data() {
    return {
      newRequests: {
        companyRequestsCount: 0,
        driversCount: 0
      }
    }
  }
}
</script>

<style >

</style>