import axios from "axios";
import errorHandler from "@/helpers/errorHandler";

export default {
  apply: async function (driverApplicationModel) {
    var instance = axios.create({
      baseURL: window.config.baseApiUrl,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    var response = {};

    await instance.post('/api/driver/apply', driverApplicationModel)
      .then(res => {
        response = res;
      })
      .catch(async error => {
        response = await errorHandler.handleRequestError(error);
      });

    return response;
  }
}