<template>
  <router-view></router-view>
</template>

<script>

export default {
  created() {
    this.$store.commit('initializeState');
  }
}
</script>

<style >

</style>