<template>
  <section class="dashboard">
    <div class="container">
      <div class="row">
        <SidebarComponent />
        <AdvertismentContentComponent :advertisementId="advertisementId" />
      </div>
    </div>
  </section>
</template>
<script>

import AdvertismentContentComponent from '@/components/advertismentsComponents/advertismentContentComponent.vue';
import SidebarComponent from '@/components/shared/sidebarComponent.vue';

export default {
  components: { SidebarComponent, AdvertismentContentComponent },
  props: {
    advertisementId: { required: true }
  },
  methods: {
    isPageLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false
      }, 300)
    },
  }
}
</script>

<style lang="scss" scoped>

</style>