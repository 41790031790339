import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CompaniesView from '../views/companiesView.vue'
import LoginView from '../views/loginView.vue'
import DashboardView from '../views/dashboardView.vue'
import RequestsView from '../views/requestsView.vue'
import CompanyDetailsView from '../views/companyDetailsView.vue'
import DriversView from '../views/driversView.vue'
import AddDriverView from '../views/addDriverView.vue'
import DriverInfoView from '../views/driverInfoView.vue'
import AdvertismentsView from '../views/advertismentsView.vue'
import AdvertismentView from '../views/advertismentView.vue'
import UsersView from '../views/usersView.vue'
import CreateAdminView from '../views/createAdminView.vue'
import newRequestView from '../views/newRequestView.vue'
import ProfileView from '../views/profileView.vue';
import AddCompanyView from '../views/addCompanyView.vue';
import EditUserView from '@/views/editUserView';
import EditDriverView from '@/views/editDriverView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/companies',
    name: 'companies',
    component: CompaniesView
  },
  {
    path: '/company-details/:companyId',
    name: 'company details',
    component: CompanyDetailsView,
    props: true
  },
  {
    path: '/drivers',
    name: 'drivers',
    component: DriversView
  },
  {
    path: '/requests',
    name: 'requests',
    component: RequestsView
  },
  {
    path: '/add-driver',
    name: 'add driver',
    component: AddDriverView
  },

  {
    path: '/driver-info/:driverId',
    name: 'info driver',
    component: DriverInfoView,
    props: true
  },
  {
    path: '/advertisments',
    name: 'advertisments',
    component: AdvertismentsView
  },
  {
    path: '/advertisment/:advertisementId',
    name: 'advertisment',
    component: AdvertismentView,
    props: true
  }
  ,
  {
    path: '/users',
    name: 'users',
    component: UsersView
  }
  ,
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  }
  ,
  {
    path: '/add-company',
    name: 'add company',
    component: AddCompanyView
  }
  ,
  {
    path: '/create-admin',
    name: 'create admin',
    component: CreateAdminView
  },
  {
    path: '/new-request/:companyRequestId',
    name: 'new request',
    component: newRequestView,
    props: true
  },
  {
    path: '/edit-user/:userId',
    name: "edit user",
    component: EditUserView,
    props: true
  },
  {
    path: '/edit-driver/:driverId',
    name: 'edit driver',
    component: EditDriverView,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
