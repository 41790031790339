<template>
  <div class="row_right">
    <DashboardTitleComponent title="Drivers" />
    <div class="filter-tab">
      <div class="search">
        <input type="text" placeholder="Search by name" class="search-field" v-model="getDriversRequestModel.name" />
        <button type="button" class="search-button" @click="getDriversViaFindButton">
          <img src="@/assets/images/search.svg">
        </button>
      </div>
      <a href="#" data-toggle="modals" data-target="#modals-filter" class="filter-button">
        <span>Filter</span>
        <img src="@/assets/images/filter.svg" alt="filter">
      </a>
      <router-link to="/add-driver" class="add-button">Add Driver</router-link>
    </div>
    <div>
      <ul class="responsive-table drivers-table">
        <li class="table-header">
          <div class="col col-1">№</div>
          <div class="col col-2">Full name</div>
          <div class="col col-3">Phone number</div>
          <div class="col col-4">Driver type</div>
          <div class="col col-5">Created date</div>
          <div class="col col-6">&nbsp;</div>
        </li>
        <DriverListComponent v-for="(driver, index) in drivers" :key="driver.driverId" :number="index + 1" :id="driver.driverId"
          :fullName="(driver.firstName + ' ' + driver.lastName)" :phoneNumber="driver.phoneNumber"
          :driverTypes="driver.driverTypes" :createdDate="driver.createdDate" 
          :is-read-by-admin="driver.isReadByAdmin" :is-deleted="driver.isDeleted"/>
      </ul>
    </div>

    <div class="pagination-container">
      <vue-awesome-paginate v-if="driversSuccessfullyLoaded" :total-items="pagination.totalCount"
        v-model="pagination.page" :items-per-page="pagination.size" :max-pages-shown="5"
        paginationContainerClass="pagination" active-page-class="active-page" :hide-prev-next-when-ends="true"
        :on-click="getDriversForPage" paginateButtonsClass="page" back-button-class="back-btn"
        next-button-class="next-btn">
        <template #prev-button>
          <span>
            <img src="@/assets/images/left-pagination.svg" height="12" />
          </span>
        </template>
        <template #next-button>
          <span>
            <img src="@/assets/images/right-pagination.svg" height="12" />
          </span>
        </template>
      </vue-awesome-paginate>
    </div>
  </div>

  <section class="modals modal_filter" id="modals-filter">
    <div class="modal_title">
      <h3>
        Filter
      </h3>
      <a href="№" class="close btn-close" data-close="modals">⨉</a>

    </div>
    <div class="row">
      <div class="form_block form_chek_radio">
        <div class="row_form bot_divider">

          <div class="col_form">
            <div class="col_title">
              Driver type
            </div>

            <div class="form-check" v-for="driverType in driverTypes" :key="driverType.id">
              <input class="form-styled checkbox_styled" type="checkbox" :value="driverType.id"
                :id="`driverType_${driverType.id}`" v-model="getDriversRequestModel.driverTypeIds">
              <label :for="`driverType_${driverType.id}`">
                {{ driverType.type }}
              </label>
            </div>

          </div>

          <div class="col_form">
            <div class="col_title">
              Experience
            </div>
            <div class="form-check" v-for="experience in experiences" :key="experience.id">
              <input class="form-styled radio_styled" type="radio" name="radioGroup_1"
                :id="`experience_${experience.id}`" v-model="getDriversRequestModel.experienceId"
                :value="experience.id" />
              <label :for="`experience_${experience.id}`">
                {{ experience.value }}
              </label>
            </div>
          </div>

          <div class="col_form">
            <div class="col_title">
              Route Type
            </div>
            <div class="form-check" v-for="routeType in routeTypes" :key="routeType.id">
              <input class="form-styled checkbox_styled" type="checkbox" :id="`routeType_${routeType.id}`"
                v-model="getDriversRequestModel.routeTypeIds" :value="routeType.id" />
              <label :for="`routeType_${routeType.id}`">
                {{ routeType.type }}
              </label>
            </div>
          </div>
        </div>

        <div class="row_form">

          <div class="col_form">
            <div class="col_title">
              Freight Type
            </div>
            <div class="form-check" v-for="freightType in freightTypes" :key="freightType.id">
              <input class="form-styled checkbox_styled" type="checkbox" :id="`freightType_${freightType.id}`"
                v-model="getDriversRequestModel.freightTypeIds" :value="freightType.id" />
              <label :for="`freightType_${freightType.id}`">
                {{ freightType.type }}
              </label>
            </div>
          </div>

          <div class="col_form">
            <div class="col_title">
              Endorsements
            </div>
            <div class="form-check" v-for="endorsement in endorsements" :key="endorsement.id">
              <input class="form-styled checkbox_styled" type="checkbox" :id="`endorsement_${endorsement.id}`"
                v-model="getDriversRequestModel.endorsementIds" :value="endorsement.id" />
              <label :for="`endorsement_${endorsement.id}`">
                {{ endorsement.name }}
              </label>
            </div>
          </div>

        </div>

      </div>
    </div>
    <div class="modal_filter_footer">
      <button class="button refresh" @click="resetGetDriversRequestModel" style="border: none; cursor: pointer;">
        <img src="@/assets/images/refresh-ccw.svg" alt="refresh">
        <span>Reset</span>
      </button>
      <button class="button apply btn-close close" data-close="modals" @click="getDriversViaFindButton"
        style="border: none;">
        Apply filter
      </button>
    </div>

  </section>
  <loading v-model:active="isLoading" :can-cancel="true" :is-full-page="fullPage" color="#26A28F" />

</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import DashboardTitleComponent from '../shared/dashboardTitleComponent.vue';
import DriverListComponent from './inComponent/driverListComponent.vue';

// services
import driverService from '@/services/driverService';
import driverTypeService from '@/services/driverTypeService';
import freightTypeService from '@/services/freightTypeService';
import routeTypeService from '@/services/routeTypeService';
import endorsementService from '@/services/endorsementService';
import experienceService from '@/services/experienceService';

export default {
  mounted() {
    var self = this;
    addEventListener('keypress', function (e) {
      if (e.key === "Enter") {
        self.getDriversViaFindButton();
      }
    });

    this.getDrivers();
    this.getDriverTypes();
    this.getFreightTypes();
    this.getRouteTypes();
    this.getEndorsements();
    this.getExperiences();
  },
  components: { DashboardTitleComponent, DriverListComponent, Loading },
  methods: {
    isPageLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false
      }, 200)
    },
    getDrivers: async function () {
      var self = this;
      var response = await driverService.getDrivers(self.getDriversRequestModel, self.pagination.page, self.pagination.size);

      if (response.data.successful) {
        self.drivers = response.data.items;

        if (self.drivers.length > 0) {
          self.driversSuccessfullyLoaded = true;
          self.pagination.totalCount = response.data.totalCount;
        } else {
          self.driversSuccessfullyLoaded = false;
        }
      }
    },
    getDriversForPage: function (pageNumber) {
      this.isPageLoading();
      this.pagination.page = pageNumber;
      this.getDrivers();
    },
    getDriversViaFindButton: function () {
      this.isPageLoading();
      this.pagination.page = 1;
      this.getDrivers();
    },
    resetGetDriversRequestModel: function () {
      this.isPageLoading();
      this.getDriversRequestModel.name = "";
      this.getDriversRequestModel.experienceId = 0;
      this.getDriversRequestModel.driverTypeIds = [];
      this.getDriversRequestModel.routeTypeIds = [];
      this.getDriversRequestModel.freightTypeIds = [];
      this.getDriversRequestModel.endorsementIds = [];
    },
    getDriverTypes: async function () {
      var response = await driverTypeService.getDriverTypes();

      if (response.data.successful) {
        this.driverTypes = response.data.items;
      }
    },
    getFreightTypes: async function () {
      var response = await freightTypeService.getFreightTypes();

      if (response.data.successful) {
        this.freightTypes = response.data.items;
      }
    },
    getRouteTypes: async function () {
      var response = await routeTypeService.getRouteTypes();

      if (response.data.successful) {
        this.routeTypes = response.data.items;
      }
    },
    getEndorsements: async function () {
      var response = await endorsementService.getEndorsements();

      if (response.data.successful) {
        this.endorsements = response.data.items;
      }
    },
    getExperiences: async function () {
      var response = await experienceService.getExperiences();

      if (response.data.successful) {
        this.experiences = response.data.items;
      }
    }
  },
  data() {
    return {

      isLoading: false,
      fullPage: true,
      // Request models
      getDriversRequestModel: {
        name: "",
        experienceId: 0,
        driverTypeIds: [],
        freightTypeIds: [],
        routeTypeIds: [],
        endorsementIds: []
      },
      // Response models
      driversSuccessfullyLoaded: false,
      drivers: [],
      driverTypes: [],
      freightTypes: [],
      routeTypes: [],
      endorsements: [],
      experiences: [],
      pagination: {
        page: 1,
        totalCount: 0,
        size: 10
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>