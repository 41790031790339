<template>
  <div class="row_right">
    <DashboardTitleComponent title="Add Driver" />
    <div class="driver-info-bar">
      <router-link class="btn-to-drivers" to="/drivers">
        <img src="@/assets/images/arrow-left.svg" alt="arrow left"> <span>Back</span>
      </router-link>
    </div>
    <section class="driver_add_main">
      <div class="form_block">
        <h3>Personal info</h3>
        <div class="row_form">
          <div class="col_form">
            <label class="form-label" :class="{ 'error_class': v$.createDriverRequestModel.firstName.$error }">
              First name
              <span>*</span>
            </label>

            <input class="form-control" :class="{ 'error_border': v$.createDriverRequestModel.firstName.$error }"
              type="text" v-model="createDriverRequestModel.firstName"
              @blur="v$.createDriverRequestModel.firstName.$touch" placeholder="Johnny">
            <div class="error_class" v-if="v$.createDriverRequestModel.firstName.$error">
              {{ v$.createDriverRequestModel.firstName.$errors[0].$message }}
            </div>
          </div>
          <div class="col_form">
            <label class="form-label" :class="{ error_class: v$.createDriverRequestModel.lastName.$error }">
              Last name
              <span>*</span>
            </label>

            <input type="text" :class="{ error_border: v$.createDriverRequestModel.lastName.$error }"
              @blur="v$.createDriverRequestModel.lastName.$touch" class="form-control" placeholder="Depp"
              v-model="createDriverRequestModel.lastName">
            <div class="error_class" v-if="v$.createDriverRequestModel.lastName.$error">
              {{ v$.createDriverRequestModel.lastName.$errors[0].$message }}
            </div>
          </div>
        </div>
        <div class="row_form">
          <div class="col_form">
            <label class="form-label" :class="{ error_class: v$.createDriverRequestModel.user.email.$error }">
              Email
              <span>*</span>
            </label>

            <input type="text" class="form-control"
              :class="{ error_border: v$.createDriverRequestModel.user.email.$error }" placeholder="example@mail.com"
              v-model="createDriverRequestModel.user.email" @blur="v$.createDriverRequestModel.user.email.$touch">
            <div class="error_class" v-if="v$.createDriverRequestModel.user.email.$error">
              {{ v$.createDriverRequestModel.user.email.$errors[0].$message }}
            </div>
          </div>
          <div class="col_form">
            <label class="form-label" :class="{ error_class: v$.createDriverRequestModel.user.phoneNumber.$error }">
              Phone number
              <span>*</span>
            </label>

            <input type="text" class="form-control"
              :class="{ error_border: v$.createDriverRequestModel.user.phoneNumber.$error }"
              placeholder="+1 (206) xxx-xxxx" v-model="createDriverRequestModel.user.phoneNumber"
              v-maska="['+1 (###) ###-####', '+1 (###) ###-####']"
              @blur="v$.createDriverRequestModel.user.phoneNumber.$touch" maxlength="17">
            <div class="error_class" v-if="v$.createDriverRequestModel.user.phoneNumber.$error">
              {{ v$.createDriverRequestModel.user.phoneNumber.$errors[0].$message }}
            </div>
          </div>
        </div>
        <div class="row_form top_block">
          <div class="col_form col_half">
            <label class="form-label" :class="{ error_class: v$.createDriverRequestModel.birthDate.$error }">
              Date of birth
              <span>*</span>
            </label>

            <input type="date" class="form-control" :class="{
              error_border:
                v$.createDriverRequestModel.birthDate.$error
            }" placeholder="dd/mm/yyyy" max="2022-01-01" v-model="createDriverRequestModel.birthDate"
              @blur="v$.createDriverRequestModel.birthDate.$touch">
            <div class="error_class" v-if="v$.createDriverRequestModel.birthDate.$error">
              {{ v$.createDriverRequestModel.birthDate.$errors[0].$message }}
            </div>
          </div>
        </div>
        <div class="row_form">
          <div class="col_form">
            <label class="form-label">
              Address
            </label>
            <input type="text" class="form-control" placeholder="Your address"
              v-model="createDriverRequestModel.address.targetAddress" maxlength="100">
          </div>
          <div class="col_form">
            <label class="form-label">City </label>
            <input type="text" class="form-control" placeholder="City" v-model="createDriverRequestModel.address.city">
          </div>
        </div>
        <div class="row_form">
          <!-- STATES -->
          <div class="col_form state_form">
            <label class="form-label">
              States {{ createDriverRequestModel.address.stateId }}
              <span>*</span>
            </label>
            <Multiselect :classes="{
              container: 'multiselect_container',
              options: 'multiselect-options multiselect_options',
              search: 'multiselect_search',
              multipleLabel: 'multiple_label'
            }" mode="single" :options="states" :openDirection="styleMult" valueProp="id" label="name"
              :hide-selected="false" :close-on-select="true" noOptionsText="No state found!"
              noResultsText="No state found!" :multiple="false" :taggable="false"
              v-model="createDriverRequestModel.address.stateId" :searchable="false" :caret="true"
              placeholder="Washington, Seattle...." />
          </div>
          <div class="col_form">
            <label class="form-label" :class="{ error_class: v$.createDriverRequestModel.address.zipCode.$error }">
              Zip code
              <span>*</span>
            </label>

            <input type="text" class="form-control"
              :class="{ error_border: v$.createDriverRequestModel.address.zipCode.$error }" placeholder="000000"
              v-model="createDriverRequestModel.address.zipCode"
              @blur="v$.createDriverRequestModel.address.zipCode.$touch" maxlength="5">
            <div class="error_class" v-if="v$.createDriverRequestModel.address.zipCode.$error">
              {{ v$.createDriverRequestModel.address.zipCode.$errors[0].$message }}
            </div>
          </div>
        </div>

      </div>
      <div class="form_block form_chek_radio">
        <h3>Required experience</h3>
        <div class="row_form bot_divider">
          <div class="col_form">
            <div class="col_title">
              Driver type <span>*</span>
            </div>

            <div v-for="driverType in driverTypes" :key="driverType.id" class="form-check">
              <input class="form-styled checkbox_styled" type="checkbox" :value="driverType.id"
                :id="`driverType_${driverType.id}`" v-model="createDriverApplicationRequestModel.driverTypeIds">
              <label :for="`driverType_${driverType.id}`">
                {{ driverType.type }}
              </label>
            </div>

          </div>
          <div class="col_form">
            <div class="col_title">
              Experience <span>*</span>
            </div>

            <div v-for="exp in experiences" :key="exp.id" class="form-check">
              <input class="form-styled radio_styled" type="radio" name="radioGroup_1" :id="`Radio_${exp.id}`"
                :value="exp.id" v-model="createDriverApplicationRequestModel.experienceId">
              <label :for="`Radio_${exp.id}`">
                {{ exp.value }}
              </label>
            </div>

          </div>
          <div class="col_form">
            <div class="col_title">
              Route Type <span>*</span>
            </div>
            <div v-for="routeType in routeTypes" :key="routeType.id" class="form-check">
              <input class="form-styled checkbox_styled" type="checkbox" :value="routeType.id"
                :id="`routeType_${routeType.id}`" v-model="createDriverApplicationRequestModel.routeTypeIds">
              <label :for="`routeType_${routeType.id}`">
                {{ routeType.type }}
              </label>
            </div>
          </div>
        </div>
        <div class="row_form">
          <div class="col_form">
            <div class="col_title">
              Freight Type <span>*</span>
            </div>
            <div v-for="freightType in freightTypes" :key="freightType.id" class="form-check">
              <input class="form-styled checkbox_styled" type="checkbox" :value="freightType.id"
                :id="`freightType_${freightType.id}`" v-model="createDriverApplicationRequestModel.freightTypeIds">
              <label :for="`freightType_${freightType.id}`">
                {{ freightType.type }}
              </label>
            </div>
          </div>
          <div class="col_form">
            <div class="col_title">
              Endorsements 
            </div>
            <div v-for="end in endorsements" :key="end.id" class="form-check">
              <input class="form-styled checkbox_styled" type="checkbox" :value="end.id" :id="`endorsement_${end.id}`"
                v-model="createDriverApplicationRequestModel.endorsementIds">
              <label :for="`endorsement_${end.id}`">
                {{ end.name }}
              </label>
            </div>
          </div>
        </div>

      </div>
      <div class="bot_button">
        <!-- TO DO - disable button until validation is not passed -->
        <button class="save-button" @click="createDriver" :disabled="this.v$.$invalid"
          :class="{ 'but_green': !this.v$.$invalid }">
          Submit
        </button>
      </div>
    </section>
  </div>

  <!-- Modal -->
  <div class="modals modal_notification" id="modal">
    <div class="content_group">
      <img :src="require(`@/assets/images/${modal.selectedIcon}`)" alt="Notification icon">
      <div class="modal_content">
        <span class="not_title">{{ modal.title }}</span>
        <span class="modal_text">{{ modal.body }}</span>
      </div>
    </div>
    <a href="№" class="close btn-close" data-close="modals">⨉</a>
  </div>
  <loading v-model:active="isLoading" :can-cancel="true" :is-full-page="fullPage" color="#26A28F" />
</template>

<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import DashboardTitleComponent from '../shared/dashboardTitleComponent.vue';
import driverTypeService from '@/services/driverTypeService';
import experienceService from '@/services/experienceService';
import routeTypeService from '@/services/routeTypeService';
import freightTypeService from '@/services/freightTypeService';
import endorsementService from '@/services/endorsementService';
import stateService from '@/services/stateService';
import driverService from '@/services/driverService';
import driverApplicationService from '@/services/driverApplicationService';
import Multiselect from '@vueform/multiselect';
import "@vueform/multiselect/themes/default.css";
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength, helpers } from '@vuelidate/validators'
import { validateEmail, validateDate, validateZipCode } from '@/services/validationService.js';

export default {
  setup: () => ({ v$: useVuelidate() }),
  mounted() {
    this.getDriverTypes();
    this.getExperiences();
    this.getRouteTypes();
    this.getFreightTypes();
    this.getEndorsements();
    this.getStates();
  },
  components: { 
    DashboardTitleComponent, 
    Multiselect, 
    Loading 
  },
  methods: {
    getDriverTypes: async function () {
      var response = await driverTypeService.getDriverTypes();
      if (response.data.successful) {
        this.driverTypes = response.data.items;
      }
    },
    getExperiences: async function () {
      var response = await experienceService.getExperiences();
      if (response.data.successful) {
        this.experiences = response.data.items;
      }
    },
    getRouteTypes: async function () {
      var response = await routeTypeService.getRouteTypes();
      if (response.data.successful) {
        this.routeTypes = response.data.items;
      }
    },
    getFreightTypes: async function () {
      var response = await freightTypeService.getFreightTypes();
      if (response.data.successful) {
        this.freightTypes = response.data.items;
      }
    },
    getEndorsements: async function () {
      var response = await endorsementService.getEndorsements();
      if (response.data.successful) {
        this.endorsements = response.data.items;
      }
    },
    getStates: async function () {
      var response = await stateService.getStates();
      if (response.data.successful) {
        this.states = response.data.items;
      }
    },
    createDriver: async function () {
      var self = this;
      if (!this.v$.$invalid) {
        self.isLoading = true;
        var response = await driverService.createDriverAsAdmin(this.createDriverRequestModel);
        self.isLoading = false;

        if (response.data.successful) {
          self.toggleModal(self.modal.successIcon, "Success", "Driver has been created");
          setTimeout(function () {
            self.$router.push({
              path: '/drivers'
            });
          }, 3000);
          this.createDriverApplicationRequestModel.driverId = response.data.item;

          self.isLoading = true;
          response = await driverApplicationService.apply(this.createDriverApplicationRequestModel);
          self.isLoading = false;
        } else {
          self.toggleModal(self.modal.errorIcon, "Something went wrong", response.data.errors[0].messages[0]);
        }
      } else {
        self.toggleModal(self.modal.warningIcon, "Warning", "Please fill out the form properly");
      }
    },
    toggleModal: function (imgSrc, title, body) {
      var self = this;
      self.modal.selectedIcon = imgSrc;
      self.modal.title = title;
      self.modal.body = body;

      var overlay_modals = document.querySelector(".overlay");
      const toast_modal = document.getElementById("modal");
      toast_modal.classList.add('open');
      overlay_modals.classList.remove("hidden");
      setTimeout(function () {
        toast_modal.classList.remove('open');
        overlay_modals.classList.add("hidden");
      }, 3000);
    }
  },
  data() {
    return {

      isLoading: false,
      fullPage: true,

      // Request models,
      createDriverRequestModel: {
        user: {
          email: null,
          phoneNumber: null
        },
        firstName: null,
        lastName: null,
        birthDate: null,
        address: {
          targetAddress: null,
          city: null,
          stateId: null,
          zipCode: null
        }
      },
      createDriverApplicationRequestModel: {
        driverId: null,
        hasCdl: true,
        experienceId: null,
        driverTypeIds: [],
        freightTypeIds: [],
        routeTypeIds: [],
        endorsementIds: []
      },
      // Response models
      driverTypes: [],
      experiences: [],
      routeTypes: [],
      freightTypes: [],
      endorsements: [],
      states: [],
      modal: {
        title: "",
        body: "",
        successIcon: "success_icon.svg",
        warningIcon: "warning_icon.svg",
        errorIcon: "error_icon.svg",
        selectedIcon: "success_icon.svg"
      }
    }
  },
  validations() {
    return {
      createDriverRequestModel: {
        user: {
          email: {
            required: helpers.withMessage('Email is required!', required),
            validateEmail: helpers.withMessage('Invalid email!', validateEmail)
          },
          phoneNumber: {
            required: helpers.withMessage('Phone number is required!', required),
            minLength: minLength(17),
            maxLength: maxLength(17)
          }
        },
        firstName: {
          required: helpers.withMessage('First name is required!', required),
          minLength: minLength(2),
          maxLength: maxLength(30),
        },
        lastName: {
          required: helpers.withMessage('Last name is required!', required),
          minLength: minLength(2),
          maxLength: maxLength(30),
        },
        birthDate: {
          required: helpers.withMessage('Birth date is required!', required),
          validateDate: helpers.withMessage('Invalid date!', validateDate)
        },
        address: {
          zipCode: {
            required: helpers.withMessage('Zip code is required!', required),
            validateZipCode: helpers.withMessage('Invalid zip code!', validateZipCode)
          },
          stateId: {
            required: helpers.withMessage('State is required!', required)
          }
        }
      },
      createDriverApplicationRequestModel: {
        experienceId: {
          required
        },
        driverTypeIds: {
          required,
          minLength: minLength(1)
        },
        freightTypeIds: {
          required,
          minLength: minLength(1)
        },
        routeTypeIds: {
          required,
          minLength: minLength(1)
        }
      }
    }
  }
}
</script>
