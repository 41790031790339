import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/css/styles.css'
import store from './store'
import Maska from "maska";
// PAGINATION
import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";

// TO DO - UNCOMMENT BEFORE DEPLOYING
import { config } from '@/configuration/config'
// import { config } from '@/configuration/config.js';

window.config = config;
window.store = store;

createApp(App)
  .use(VueAwesomePaginate)
  .use(Maska)
  .use(store)
  .use(router)
  .mount('#app')
