<template>
  <div class="row_right">
    <DashboardTitleComponent title="Add Company" />
    <div class="driver-info-bar">
      <router-link class="btn-to-drivers" to="/companies">
        <img src="@/assets/images/arrow-left.svg" alt="arrow left"> <span>Back</span>
      </router-link>
    </div>
    <section class="company_add_main">
      <div class="form_block">
        <div class="form_row_left">
          <img :src="selectedFileBase64" alt="upload" v-if="selectedFileBase64">
          <img src="@/assets/images/upload-cloud.png" alt="upload" v-if="!selectedFileBase64">
          <p>Company Logo</p>
          <label for="file_upload" class="button" style="cursor: pointer">Browse</label>
          <input type="file" id="file_upload" name="file_upload" style="display: none;" required
            @change="processSelectedFile">
        </div>
        <div class="form_row_right">

          <div class="col_form top_company">
            <label for="company" :class="{ error_class: v$.company.companyName.$error }">Company name
              <span>*</span></label>


            <input type="text" name="company" v-model="company.companyName"
              :class="{ error_border: v$.company.companyName.$error }" @blur="v$.company.companyName.$touch">
            <div class="error_class" v-if="v$.company.companyName.$error">
              {{ v$.company.companyName.$errors[0].$message }}
            </div>
          </div>

          <div class="col_form bot_company">
            <h3>Contact Info</h3>
            <div class="bot_row_form">
              <div class="form_input">
                <label :class="{ error_class: v$.company.firstName.$error }">First name <span>*</span></label>
                <input type="text" class="form-control" v-model="company.firstName"
                  :class="{ error_border: v$.company.firstName.$error }" @blur="v$.company.firstName.$touch">
                <div class="error_class" v-if="v$.company.firstName.$error">
                  {{ v$.company.firstName.$errors[0].$message }}
                </div>
              </div>
              <div class="form_input">
                <label :class="{ error_class: v$.company.lastName.$error }">Last name <span>*</span></label>
                <input type="text" class="form-control" v-model="company.lastName"
                  :class="{ error_border: v$.company.lastName.$error }" @blur="v$.company.lastName.$touch">
                <div class="error_class" v-if="v$.company.lastName.$error">
                  {{ v$.company.lastName.$errors[0].$message }}
                </div>
              </div>
            </div>
            <div class="bot_row_form">
              <div class="form_input">
                <label :class="{ error_class: v$.company.email.$error }">Email <span>*</span></label>
                <input type="text" class="form-control" v-model="company.email"
                  :class="{ error_border: v$.company.email.$error }" @blur="v$.company.email.$touch">
                <div class="error_class" v-if="v$.company.email.$error">
                  {{ v$.company.email.$errors[0].$message }}
                </div>
              </div>
              <div class="form_input">
                <label :class="{ error_class: v$.company.phoneNumber.$error }">Phone number <span>*</span></label>
                <input type="text" class="form-control" v-maska="['+1 (###) ###-####', '+1 (###) ###-####']"
                  v-model="company.phoneNumber" :class="{ error_border: v$.company.phoneNumber.$error }"
                  @blur="v$.company.phoneNumber.$touch" maxlength="17" placeholder="+1 (206) xxx-xxxx">
                <div class="error_class" v-if="v$.company.phoneNumber.$error">
                  {{ v$.company.phoneNumber.$errors[0].$message }}
                </div>
              </div>
            </div>
            <!-- <a href="#" class="add-new"> <img src="@/assets/images/plus-circle.svg" alt="plus"> <span>Add new</span></a> -->
          </div>
          <div class="btn_container">
            <button class="button" :class="{ 'but_green': !this.v$.$invalid }" :disabled="this.v$.$invalid"
              @click="createCompany">Submit</button>
          </div>
        </div>

      </div>
    </section>
  </div>

  <!-- Modal -->
  <div class="modals modal_notification" id="modal">
    <div class="content_group">
      <img :src="require(`@/assets/images/${modal.selectedIcon}`)" alt="Notification icon">
      <div class="modal_content">
        <span class="not_title">{{ modal.title }}</span>
        <span class="modal_text">{{ modal.body }}</span>
      </div>
    </div>
    <a href="№" class="close btn-close" data-close="modals">⨉</a>
  </div>
  <loading v-model:active="isLoading" :can-cancel="true" :is-full-page="fullPage" />
</template>

<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength, email, helpers } from '@vuelidate/validators'
import DashboardTitleComponent from '../shared/dashboardTitleComponent.vue';
import { imageFileAllowedExtensions } from '@/constants';
import companyService from '@/services/companyService';

export default {

  setup: () => ({ v$: useVuelidate() }),
  components: {
    DashboardTitleComponent,
    Loading
  },
  methods: {
    createCompany: async function () {
      var self = this;
      var formData = new FormData();
      formData.append("companyName", self.company.companyName);
      formData.append("firstName", self.company.firstName);
      formData.append("lastName", self.company.lastName);
      formData.append("email", self.company.email);
      formData.append("phoneNumber", self.company.phoneNumber);

      const selectedFile = document.getElementById("file_upload").files[0];
      if (selectedFile) {
        formData.append("logo", selectedFile);
      }

      self.isLoading = true;
      var response = await companyService.createCompany(formData);
      self.isLoading = false;
      if (response.data.successful) {
        self.toggleModal(self.modal.successIcon, "Success", "Company has been created");
        setTimeout(function () {
          self.$router.push({
            path: '/companies'
          });
        }, 3000);
      } else {
        self.toggleModal(self.modal.errorIcon, "Something went wrong", response.data.errors[0].messages[0]);
      }
    },
    convertFileToBase64Encoded: async function (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    processSelectedFile: async function (e) {
      var self = this;
      if (!e.target.files[0]) {
        return;
      }

      const file = e.target.files[0];
      let extension = file.name.split(".").pop().toLowerCase();
      if (!imageFileAllowedExtensions.some(x => x.toLowerCase() === extension)) {
        self.toggleModal(self.modal.errorIcon, "Warning", "Invalid extension");
        return;
      }

      if (file.size <= 0) {
        self.toggleModal(self.modal.errorIcon, "Warning", "Invalid size");
        return;
      }

      try {
        let base64Encoded = await self.convertFileToBase64Encoded(file);
        self.selectedFileBase64 = base64Encoded;
      }
      catch (error) {
        self.toggleModal(self.modal.errorIcon, "Something went wrong", "Please try later");
      }
    },
    toggleModal: function (imgSrc, title, body) {
      var self = this;
      self.modal.selectedIcon = imgSrc;
      self.modal.title = title;
      self.modal.body = body;

      var overlay_modals = document.querySelector(".overlay");
      const toast_modal = document.getElementById("modal");
      toast_modal.classList.add('open');
      overlay_modals.classList.remove("hidden");
      setTimeout(function () {
        toast_modal.classList.remove('open');
        overlay_modals.classList.add("hidden");
      }, 3000);
    }
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      company: {
        companyName: null,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        email: null
      },
      selectedFileBase64: null,
      modal: {
        title: "",
        body: "",
        successIcon: "success_icon.svg",
        warningIcon: "warning_icon.svg",
        errorIcon: "error_icon.svg",
        selectedIcon: "success_icon.svg"
      }
    }
  },
  validations() {
    return {
      company: {
        companyName: {
          required: helpers.withMessage("Company name is required!", required),
          minLength: minLength(2),
          maxLength: maxLength(200)
        },
        firstName: {
          required: helpers.withMessage('First name is required!', required),
          minLength: minLength(2),
          maxLength: maxLength(30),
        },
        lastName: {
          required: helpers.withMessage('Last name is required!', required),
          minLength: minLength(2),
          maxLength: maxLength(30),
        },
        email: {
          required: helpers.withMessage('Email is required!', required),
          email,
        },
        phoneNumber: {
          required: helpers.withMessage('Phone number is required!', required),
          minLength: minLength(17),
          maxLength: maxLength(17),
        }
      }
    }
  }
}
</script>
