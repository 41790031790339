<template>
  <div class="row_right">
    <DashboardTitleComponent title="Edit Driver" />
    <div class="driver-info-bar">
      <router-link class="btn-to-drivers" to="/drivers">
        <img src="@/assets/images/arrow-left.svg" alt="arrow left">
        <span>Back</span>
      </router-link>
    </div>
    <section class="driver_add_main">
      <div class="form_block">
        <h3>Personal info</h3>
        <div class="row_form">
          <div class="col_form">
            <label class="form-label" :class="{ 'error_class': v$.editDriverRequestModel.firstName.$error }">
              First name
              <span>*</span>
            </label>

            <input class="form-control" :class="{ 'error_border': v$.editDriverRequestModel.firstName.$error }"
              type="text" v-model="editDriverRequestModel.firstName" @blur="v$.editDriverRequestModel.firstName.$touch"
              placeholder="Johnny">
            <div class="error_class" v-if="v$.editDriverRequestModel.firstName.$error">
              {{ v$.editDriverRequestModel.firstName.$errors[0].$message }}
            </div>
          </div>
          <div class="col_form">
            <label class="form-label" :class="{ error_class: v$.editDriverRequestModel.lastName.$error }">
              Last name
              <span>*</span>
            </label>

            <input type="text" :class="{ error_border: v$.editDriverRequestModel.lastName.$error }"
              @blur="v$.editDriverRequestModel.lastName.$touch" class="form-control" placeholder="Depp"
              v-model="editDriverRequestModel.lastName">
            <div class="error_class" v-if="v$.editDriverRequestModel.lastName.$error">
              {{ v$.editDriverRequestModel.lastName.$errors[0].$message }}
            </div>
          </div>
        </div>
        <div class="row_form">
          <div class="col_form">
            <label class="form-label" :class="{ error_class: v$.editDriverRequestModel.email.$error }">
              Email
              <span>*</span>
            </label>

            <input type="text" class="form-control" :class="{ error_border: v$.editDriverRequestModel.email.$error }"
              placeholder="example@mail.com" v-model="editDriverRequestModel.email"
              @blur="v$.editDriverRequestModel.email.$touch">
            <div class="error_class" v-if="v$.editDriverRequestModel.email.$error">
              {{ v$.editDriverRequestModel.email.$errors[0].$message }}
            </div>
          </div>
          <div class="col_form">
            <label class="form-label" :class="{ error_class: v$.editDriverRequestModel.phoneNumber.$error }">
              Phone number
              <span>*</span>
            </label>

            <input type="text" class="form-control"
              :class="{ error_border: v$.editDriverRequestModel.phoneNumber.$error }" placeholder="+1 (206) xxx-xxxx"
              v-model="editDriverRequestModel.phoneNumber" v-maska="['+1 (###) ###-####', '+1 (###) ###-####']"
              @blur="v$.editDriverRequestModel.phoneNumber.$touch">
            <div class="error_class" v-if="v$.editDriverRequestModel.phoneNumber.$error">
              {{ v$.editDriverRequestModel.phoneNumber.$errors[0].$message }}
            </div>
          </div>
        </div>
        <div class="row_form top_block">
          <div class="col_form col_half">
            <label class="form-label" :class="{ error_class: v$.editDriverRequestModel.dateOfBirth.$error }">
              Date of birth
              <span>*</span>
            </label>

            <input type="date" class="form-control"
              :class="{ error_border: v$.editDriverRequestModel.dateOfBirth.$error }" placeholder="dd/mm/yyyy"
              max="2022-01-01" v-model="editDriverRequestModel.dateOfBirth"
              @blur="v$.editDriverRequestModel.dateOfBirth.$touch">
            <div class="error_class" v-if="v$.editDriverRequestModel.dateOfBirth.$error">
              {{ v$.editDriverRequestModel.dateOfBirth.$errors[0].$message }}
            </div>
          </div>
        </div>
        <div class="row_form">
          <div class="col_form">
            <label class="form-label">
              Address
            </label>
            <input type="text" class="form-control" placeholder="Your address" v-model="editDriverRequestModel.address"
              maxlength="100">
          </div>
          <div class="col_form">
            <label class="form-label">City </label>
            <input type="text" class="form-control" placeholder="City" v-model="editDriverRequestModel.city">
          </div>
        </div>
        <div class="row_form">
          <!-- STATES -->
          <div class="col_form state_form">
            <label class="form-label">
              States
              <span>*</span>
            </label>

            <Multiselect :classes="{
              container: 'multiselect_container',
              options: 'multiselect-options multiselect_options',
              search: 'multiselect_search',
              multipleLabel: 'multiple_label'
            }" mode="single" :options="states" :openDirection="styleMult" valueProp="id" label="name"
              :hide-selected="false" :close-on-select="true" noOptionsText="No state found!"
              noResultsText="No state found!" :multiple="false" :taggable="false"
              v-model="editDriverRequestModel.stateId" :searchable="false" :caret="true"
              placeholder="Washington, Seattle...." />
          </div>
          <div class="col_form">
            <label class="form-label" :class="{ error_class: v$.editDriverRequestModel.zipCode.$error }">
              Zip code
              <span>*</span>
            </label>

            <input type="text" class="form-control" :class="{ error_border: v$.editDriverRequestModel.zipCode.$error }"
              placeholder="000000" v-model="editDriverRequestModel.zipCode"
              @blur="v$.editDriverRequestModel.zipCode.$touch" maxlength="5">
            <div class="error_class" v-if="v$.editDriverRequestModel.zipCode.$error">
              {{ v$.editDriverRequestModel.zipCode.$errors[0].$message }}
            </div>
          </div>
        </div>

      </div>
      <div class="form_block form_chek_radio">
        <h3>Required experience</h3>
        <div class="row_form bot_divider">
          <div class="col_form">
            <div class="col_title">
              Driver type <span>*</span>
            </div>

            <div v-for="driverType in driverTypes" :key="driverType.id" class="form-check">
              <input class="form-styled checkbox_styled" type="checkbox" :value="driverType.id"
                :id="`driverType_${driverType.id}`" v-model="editDriverRequestModel.driverTypeIds">
              <label :for="`driverType_${driverType.id}`">
                {{ driverType.type }}
              </label>
            </div>

          </div>
          <div class="col_form">
            <div class="col_title">
              Experience <span>*</span>
            </div>

            <div v-for="exp in experiences" :key="exp.id" class="form-check">
              <input class="form-styled radio_styled" type="radio" name="radioGroup_1" :id="`Radio_${exp.id}`"
                :value="exp.id" v-model="editDriverRequestModel.experienceId">
              <label :for="`Radio_${exp.id}`">
                {{ exp.value }}
              </label>
            </div>

          </div>
          <div class="col_form">
            <div class="col_title">
              Route Type <span>*</span>
            </div>
            <div v-for="routeType in routeTypes" :key="routeType.id" class="form-check">
              <input class="form-styled checkbox_styled" type="checkbox" :value="routeType.id"
                :id="`routeType_${routeType.id}`" v-model="editDriverRequestModel.routeTypeIds">
              <label :for="`routeType_${routeType.id}`">
                {{ routeType.type }}
              </label>
            </div>
          </div>
        </div>
        <div class="row_form">
          <div class="col_form">
            <div class="col_title">
              Freight Type <span>*</span>
            </div>
            <div v-for="freightType in freightTypes" :key="freightType.id" class="form-check">
              <input class="form-styled checkbox_styled" type="checkbox" :value="freightType.id"
                :id="`freightType_${freightType.id}`" v-model="editDriverRequestModel.freightTypeIds">
              <label :for="`freightType_${freightType.id}`">
                {{ freightType.type }}
              </label>
            </div>
          </div>
          <div class="col_form">
            <div class="col_title">
              Endorsements
            </div>
            <div v-for="end in endorsements" :key="end.id" class="form-check">
              <input class="form-styled checkbox_styled" type="checkbox" :value="end.id" :id="`endorsement_${end.id}`"
                v-model="editDriverRequestModel.endorsementIds">
              <label :for="`endorsement_${end.id}`">
                {{ end.name }}
              </label>
            </div>
          </div>
        </div>

      </div>
      <div class="bot_button">
        <!-- TO DO - disable button until validation is not passed -->
        <button class="submit-button" style="border: none" @click="editDriver" :disabled="this.v$.$invalid" :class="{
          'but_green': !this.v$.$invalid
        }">
          Submit
        </button>
      </div>
    </section>
  </div>

  <!-- Modal -->
  <div class="modals modal_notification" id="modal">
    <div class="content_group">
      <img :src="require(`@/assets/images/${modal.selectedIcon}`)" alt="Notification icon">
      <div class="modal_content">
        <span class="not_title">{{ modal.title }}</span>
        <span class="modal_text">{{ modal.body }}</span>
      </div>
    </div>
    <a href="№" class="close btn-close" data-close="modals">⨉</a>
  </div>

</template>

<script>

import DashboardTitleComponent from '../shared/dashboardTitleComponent.vue';
import driverTypeService from '@/services/driverTypeService';
import experienceService from '@/services/experienceService';
import routeTypeService from '@/services/routeTypeService';
import freightTypeService from '@/services/freightTypeService';
import endorsementService from '@/services/endorsementService';
import stateService from '@/services/stateService';
import driverService from '@/services/driverService';
import Multiselect from '@vueform/multiselect';
import "@vueform/multiselect/themes/default.css";
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength, helpers } from '@vuelidate/validators'
import { validateEmail, validateDate, validateZipCode } from '@/services/validationService.js';

export default {
  setup: () => ({ v$: useVuelidate() }),
  mounted() {
    this.getDriverDetails();

    this.getDriverTypes();
    this.getExperiences();
    this.getRouteTypes();
    this.getFreightTypes();
    this.getEndorsements();
    this.getStates();
  },
  components: {
    DashboardTitleComponent,
    Multiselect
  },
  methods: {
    getDriverDetails: async function () {
      var self = this;
      var response = await driverService.getDriverDetails(self.driverId);
      if (response.data.successful) {
        var driver = response.data.item;

        self.editDriverRequestModel.firstName = driver.firstName;
        self.editDriverRequestModel.lastName = driver.lastName;
        self.editDriverRequestModel.email = driver.email;
        self.editDriverRequestModel.phoneNumber = driver.phoneNumber;
        self.editDriverRequestModel.city = driver.city;
        self.editDriverRequestModel.address = driver.address;
        self.editDriverRequestModel.zipCode = driver.zipCode;
        self.editDriverRequestModel.dateOfBirth = this.dateToPrettyFormat(new Date(driver.dateOfBirth));

        self.editDriverRequestModel.experienceId = driver.experience.id;
        self.editDriverRequestModel.stateId = driver.state.id;

        self.editDriverRequestModel.driverTypeIds = [];
        driver.driverTypes.forEach(x => self.editDriverRequestModel.driverTypeIds.push(x.id));

        self.editDriverRequestModel.freightTypeIds = [];
        driver.freightTypes.forEach(x => self.editDriverRequestModel.freightTypeIds.push(x.id));

        self.editDriverRequestModel.routeTypeIds = [];
        driver.routeTypes.forEach(x => self.editDriverRequestModel.routeTypeIds.push(x.id));

        self.editDriverRequestModel.endorsementIds = [];
        driver.endorsements.forEach(x => self.editDriverRequestModel.endorsementIds.push(x.id));
      } else {
        self.toggleModal(self.modal.errorIcon, "Something went wrong", response.data.errors[0].messages[0]);
      }
    },
    getDriverTypes: async function () {
      var response = await driverTypeService.getDriverTypes();
      if (response.data.successful) {
        this.driverTypes = response.data.items;
      }
    },
    getExperiences: async function () {
      var response = await experienceService.getExperiences();
      if (response.data.successful) {
        this.experiences = response.data.items;
      }
    },
    getRouteTypes: async function () {
      var response = await routeTypeService.getRouteTypes();
      if (response.data.successful) {
        this.routeTypes = response.data.items;
      }
    },
    getFreightTypes: async function () {
      var response = await freightTypeService.getFreightTypes();
      if (response.data.successful) {
        this.freightTypes = response.data.items;
      }
    },
    getEndorsements: async function () {
      var response = await endorsementService.getEndorsements();
      if (response.data.successful) {
        this.endorsements = response.data.items;
      }
    },
    getStates: async function () {
      var response = await stateService.getStates();
      if (response.data.successful) {
        this.states = response.data.items;
      }
    },
    editDriver: async function () {
      var self = this;
      var response = await driverService.editDriver(self.editDriverRequestModel);
      if (response.data.successful) {
        this.getDriverDetails();
        self.toggleModal(self.modal.successIcon, "Success", "Driver has been edited");
        setTimeout(function () {
          self.$router.push({
            path: '/drivers'
          });
        }, 3000);
      } else {
        self.toggleModal(self.modal.errorIcon, "Something went wrong", response.data.errors[0].messages[0]);
      }
    },
    dateToPrettyFormat(date) {
      let year = date.getFullYear();
      let monthIndex = date.getMonth();
      let day = date.getDate();

      return year + '-' + (monthIndex + 1 < 10 ? '0' : '') + (monthIndex + 1) + '-' + (day < 10 ? '0' : '') + day;
    },
    toggleModal: function (imgSrc, title, body) {
      var self = this;
      self.modal.selectedIcon = imgSrc;
      self.modal.title = title;
      self.modal.body = body;

      var overlay_modals = document.querySelector(".overlay");
      const toast_modal = document.getElementById("modal");
      toast_modal.classList.add('open');
      overlay_modals.classList.remove("hidden");
      setTimeout(function () {
        toast_modal.classList.remove('open');
        overlay_modals.classList.add("hidden");
      }, 3000);
    }
  },
  data() {
    return {
      // Request models,
      editDriverRequestModel: {
        driverId: this.driverId,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        dateOfBirth: '',
        address: '',
        city: '',
        stateId: null,
        zipCode: '',
        experienceId: null,
        driverTypeIds: [],
        freightTypeIds: [],
        routeTypeIds: [],
        endorsementIds: []
      },
      // Response models
      driverTypes: [],
      experiences: [],
      routeTypes: [],
      freightTypes: [],
      endorsements: [],
      states: [],
      modal: {
        title: "",
        body: "",
        successIcon: "success_icon.svg",
        warningIcon: "warning_icon.svg",
        errorIcon: "error_icon.svg",
        selectedIcon: "success_icon.svg"
      }
    }
  },
  validations() {
    return {
      editDriverRequestModel: {
        firstName: {
          required: helpers.withMessage("First name is required!", required),
          minLength: minLength(2),
          maxlength: maxLength(200)
        },
        lastName: {
          required: helpers.withMessage("Last name is required!", required),
          minLength: minLength(2),
          maxlength: maxLength(200)
        },
        email: {
          required: helpers.withMessage("Email is required!", required),
          validateEmail: helpers.withMessage("Email is invalid!", validateEmail)
        },
        phoneNumber: {
          required: helpers.withMessage("Phone number is required!", required),
          minLength: minLength(17),
          maxLength: maxLength(19)
        },
        dateOfBirth: {
          required: helpers.withMessage("Date of birth is required!", required),
          validateDate: helpers.withMessage("Date of birth is invalid!", validateDate)
        },
        stateId: {
          required: helpers.withMessage("State is required!", required)
        },
        zipCode: {
          required: helpers.withMessage("Zip code is required!", required),
          validateZipCode: helpers.withMessage("Zip code is invalid!", validateZipCode),
          minLength: minLength(5),
          maxLength: maxLength(5)
        },
        experienceId: {
          required: helpers.withMessage("Experience is required!", required)
        },
        driverTypeIds: {
          required: helpers.withMessage("Driver types are required!", required),
          minLength: minLength(1)
        },
        freightTypeIds: {
          required: helpers.withMessage("Freight types are required!", required),
          minLength: minLength(1)
        },
        routeTypeIds: {
          required: helpers.withMessage("Route types are required!", required),
          minLength: minLength(1)
        }
      }
    }
  },
  props: ['driverId']
}
</script>
