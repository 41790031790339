<template>
  <div class="chart_statics">
    <div class="chart_header">
      <h3 class="chart_name">{{ name }}</h3>
      <div class="chart_but">
        <a @click="getStatistics('Weekly')" class="but_day">Weekly</a>
        <a @click="getStatistics('Monthly')" class="but_day">Monthly</a>
      </div>
    </div>
    <div :id="chart">
    </div>
  </div>
</template>

<script>
var companiesChart = null;
var driversChart = null;

import ApexCharts from 'apexcharts'
import companyService from '@/services/companyService';
import driverService from '@/services/driverService';

export default {
  mounted() {
    this.getStatistics("Weekly");
  },
  methods: {
    getStatistics: async function (frequency) {
      var response;
      if (this.name === 'Company statistics') {
        response = await companyService.getCompaniesRegistrationStatistics(frequency);
        if (response.data.successful) {
          this.chartData.series[0].name = 'Companies';
          this.chartData.xaxis.categories = response.data.items.slice(0, 5).map(x => x.registrationDate);
          let companiesCounts = response.data.items.slice(0, 4).map(x => x.companiesCount);
          this.chartData.series[0].data = companiesCounts.slice();
          companiesCounts.sort(function(a, b) { return a - b });
          this.chartData.yaxis.max = companiesCounts[0] === companiesCounts[companiesCounts.length - 1] ? 5 : companiesCounts[companiesCounts.length - 1];

          if(companiesChart) {
            companiesChart.destroy();
          }
          companiesChart = new ApexCharts(document.querySelector("#chart_first"), this.chartData);
          companiesChart.render();
        } 
      } 
      else {
        response = await driverService.getDriverRegistrationStatistics(frequency);
        if (response.data.successful) {
          this.chartData.series[0].name = 'Drivers';
          this.chartData.xaxis.categories = response.data.items.slice(0, 5).map(x => x.registrationDate);
          let driversCounts = response.data.items.slice(0, 4).map(x => x.driversCount);
          this.chartData.series[0].data = driversCounts.slice();
          driversCounts.sort(function(a, b) { return a - b });
          this.chartData.yaxis.max = driversCounts[0] === driversCounts[driversCounts.length - 1] ? 10 : driversCounts[driversCounts.length - 1];

          if(driversChart) {
            driversChart.destroy();
          }
          driversChart = new ApexCharts(document.querySelector("#chart_second"), this.chartData);
          driversChart.render();
        } 
      }
    }
  },
  data() {
    return {
      chartData: {
        yaxis: {
          min: 1,
          max: 10,
          // tickAmount: 0,
          labels: {
            formatter: function(val) {
              return val.toFixed(0) // format the label to one decimal place
            }
          }
        },
        chart: {
          height: 300,
          type: "area"
        },
        stroke: {
          curve: 'straight'
        },
        series: [{
          name: 'Company',
          data: []
        }],
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100]
          }
        },
        xaxis: {
          categories: []
        }
      },
    }
  },
  props: ['chart', 'name']
}
</script>

<style lang="scss" scoped></style>