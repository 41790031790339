<template>
  <div class="leads">
    <div class="text_left">
      <span class="leads_text">All leads per day</span>
      <span class="leads_rate">{{ leadsRate }}</span>
    </div>
    <img src="@/assets/images/undraw.svg" alt="draw">
  </div>
</template>

<script>
export default {
  props: ["leadsRate"]
}
</script>

<style lang="scss" scoped>

</style>