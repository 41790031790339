<template>
  <section class="dashboard">
    <div class="container">
      <div class="row">
        <SidebarComponent />
        <DashboardContentComponent />
      </div>
    </div>
  </section>
  <loading v-model:active="isLoading" :can-cancel="true" :is-full-page="fullPage" color="#26A28F" />
</template>
<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import DashboardContentComponent from '@/components/dashboardComponets/dashboardContentComponent.vue';
import SidebarComponent from '@/components/shared/sidebarComponent.vue';





export default {
  mounted() {

    if (!this.isAuthenticated()) {
      this.$router.push('/login');
    }
    this.isPageLoading();

  },
  data() {
    return {

      isLoading: false,
      fullPage: true,
    }
  },
  components: { SidebarComponent, DashboardContentComponent, Loading },
  methods: {
    isPageLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>