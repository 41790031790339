<template>
  <div class="members_indicator">
    <img class="indicator_img" :src="require(`@/assets/images/${imgSrc}`)" alt="Basic">
    <div class="rates">
      <span class="rate_name">{{ title }}</span>
      <span class="rate">{{ companiesCount }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['imgSrc', 'title', 'companiesCount']
}
</script>

<style lang="scss" scoped>

</style>