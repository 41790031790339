import errorHandler from "@/helpers/errorHandler";
import authService from "./authService";

export default {
    getAdvertisements: async function (page, size) {
        var instance = await authService.getAuthorizedRequest();

        var response = {};

        await instance.get(`/api/vacancy/getAdvertisements?page=${page}&size=${size}`)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                response = await errorHandler.handleRequestError(error);
            });

        return response;
    },
    getAdvertisementDetails: async function (advId) {
        var instance = await authService.getAuthorizedRequest();

        var response = {};

        await instance.get(`/api/vacancy/getAdvertisementDetails?advertisementId=${advId}`)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                response = await errorHandler.handleRequestError(error);
            });

        return response;
    },
    markAdvertisementRead: async function (advertisementId) {
        var instance = await authService.getAuthorizedRequest();

        var response = {};

        await instance.post(`/api/vacancy/markAdvertisementRead?advertisementId=${advertisementId}`)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                response = await errorHandler.handleRequestError(error);
            });

        return response;
    },
    approveAdvertisement: async function (advertisementId) {
        var instance = await authService.getAuthorizedRequest();

        var response = {};

        await instance.post(`/api/vacancy/approveAdvertisement?advertisementId=${advertisementId}`)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                response = await errorHandler.handleRequestError(error);
            });

        return response;
    },
    editAdvertisement: async function (editRequestModel) {
        var instance = await authService.getAuthorizedRequest();

        var response = {};

        await instance.post(`/api/vacancy/editAdvertisement`, editRequestModel)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                response = await errorHandler.handleRequestError(error);
            });

        return response;
    },
    rejectAdvertisement: async function (advertisementId) {
        var instance = await authService.getAuthorizedRequest();

        var response = {};

        await instance.post(`/api/vacancy/rejectAdvertisement?advertisementId=${advertisementId}`)
            .then(res => {
                response = res;
            })
            .catch(async error => {
                response = await errorHandler.handleRequestError(error);
            });

        return response;
    }
}