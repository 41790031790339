<template>
  <section class="dashboard">
    <div class="container">
      <div class="row">
        <sidebarComponentVue />
        <editUserComponentVue :userId="userId" />
      </div>
    </div>
  </section>
  <loading v-model:active="isLoading" :can-cancel="true" :is-full-page="fullPage" color="#26A28F" />
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import sidebarComponentVue from '@/components/shared/sidebarComponent.vue';
import editUserComponentVue from '@/components/usersContentComponents/editUserComponent.vue';

export default {
  components: {
    sidebarComponentVue,
    editUserComponentVue, Loading
  },
  mounted() {
    this.isPageLoading();
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
    }
  }, methods: {
    isPageLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false
      }, 300)
    },
  },
  props: [
    'userId'
  ]
}

</script>
