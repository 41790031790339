import { createStore } from 'vuex'

export default createStore({
  state: {
    adminAuthData: {
      isAuthenticated: false,
      accessToken: null,
      accessTokenExpiry: null,
      id: null,
      email: null,
      phoneNumber: null,
      role: null,
      profilePictureBase64Encoded: null
    }
  },
  getters: {
    adminAccessToken: (state) => state.adminAuthData.accessToken,
    adminAccessTokenExpiry: (state) => state.adminAuthData.accessTokenExpiry,
    adminId: (state) => state.adminAuthData.id,
    adminEmail: (state) => state.adminAuthData.email,
    adminPhoneNumber: (state) => state.adminAuthData.phoneNumber,
    adminRole: (state) => state.adminAuthData.role,
    isAuthenticated: (state) => state.adminAuthData.isAuthenticated,
    profilePictureBase64Encoded: (state) => state.adminAuthData.profilePictureBase64Encoded
  },
  mutations: {
    initializeState(state) {
      if (localStorage.getItem('state')) {
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem('state')))
        );
        // console.log("=> State successfully initialized from local storage");
      }
    },
    authenticateSuperAdmin(state, { adminAuthData }) {
      state.adminAuthData = adminAuthData;
      state.adminAuthData.isAuthenticated = true;
      localStorage.setItem('state', JSON.stringify(state));

      // console.log("=> Super admin has been authenticated");
      // console.log("=> State saved (updated) to local storage");
    },
    logoutSuperAdmin(state) {
      state.adminAuthData.isAuthenticated = false;
      state.adminAuthData.accessToken = null;
      state.adminAuthData.accessTokenExpiry = null;
      state.adminAuthData.id = null;
      state.adminAuthData.email = null;
      state.adminAuthData.phoneNumber = null;
      state.adminAuthData.role = null;

      localStorage.setItem('state', JSON.stringify(state));
      // console.log("=> Super admin has been logged out and state has been updated");
    },
    setProfilePicture(state, { profilePictureBase64Encoded }) {
      state.adminAuthData.profilePictureBase64Encoded = profilePictureBase64Encoded;
      localStorage.setItem('state', JSON.stringify(state));
    }
  },
  actions: {
  },
  modules: {
  }
})
