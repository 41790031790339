<template>
  <router-link :to="`/advertisment/${advertisementId}`">
    <div class="adv_row" :class="{ 'marked-table': status === 'Rejected' }" style="cursor: pointer">
      <div class="adv_col_1">
        <img src="@/assets/images/approve.svg" v-if="status === 'Approved'" class="approve"/> <img :src="`data:image/png;base64, ${logoBase64Encoded}`" alt="logo"><span>{{ companyName }}</span>
      </div>
      <div class="adv_col_2">{{ subject }}</div>
      <div class="adv_col_3">{{ contacteeName }}</div>
      <div class="adv_col_4">{{ phoneNumber }}</div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ['logoBase64Encoded', 'companyName', 'subject', 'contacteeName', 'phoneNumber', 'advertisementId', 'status']
}
</script>

<style >

</style>