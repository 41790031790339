<template>
  <li class="table-row" v-if="!isDeleted">
    <div class="col col-1">
      <img :src="`data:image/png;base64, ${logoBase64Encoded}`" alt="">
      <span>{{ company }}</span>
    </div>
    <div class="col col-2">
      <span :class="plan ? plan.toLowerCase() : ''">{{ plan? plan: 'NOT CHOSEN' }}</span>
    </div>
    <div class="col col-3"><span>{{ name }}</span></div>
    <div class="col col-4"><span>{{ phoneNumber }}</span></div>
    <div class="col col-5">
      <router-link :to="`/company-details/${companyId}`" v-if="this.$store.getters.adminRole === 'SuperAdmin' && !isDeleted">
        <span>
          <img src="@/assets/images/edit.svg" alt="" />
        </span>
      </router-link>
      <a href="#" data-toggle="modals" :data-target="`#modals-delete-${companyId}`" v-if="this.$store.getters.adminRole === 'SuperAdmin' && !isDeleted">
        <!-- TO DO - implement logic -->
        <img src="@/assets/images/trash.svg" alt="">
      </a>
      <!-- <h3 v-if="isDeleted" style="font-size: 20px;">Deleted</h3> -->

    </div>

  </li>
  <div class="modals-delete modals" :id="`modals-delete-${companyId}`">
    <h3>Are You sure?</h3>
    <div class="modal-footer">
      <button class="button close btn-close" data-close="modals">Cancel</button>
      <button class="button btn-exit close btn-close" data-close="modals" @click="deleteCompany">Yes</button>
    </div>
  </div>

  <!-- Modal -->
  <div class="modals modal_notification" :id="`modal_${companyId}`">
    <div class="content_group">
      <img :src="require(`@/assets/images/${modal.selectedIcon}`)" alt="Notification icon">
      <div class="modal_content">
        <span class="not_title">{{ modal.title }}</span>
        <span class="modal_text">{{ modal.body }}</span>
      </div>
    </div>
    <a href="№" class="close btn-close" data-close="modals">⨉</a>
  </div>

  <loading v-model:active="isLoading" :can-cancel="true" :is-full-page="fullPage" color="#26A28F" />
</template>
  
<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import companyService from '@/services/companyService';

export default {
  data() {
    return {
      isLoading: false,
      fullPage: true,
      modal: {
        title: "",
        body: "",
        successIcon: "success_icon.svg",
        warningIcon: "warning_icon.svg",
        errorIcon: "error_icon.svg",
        selectedIcon: "success_icon.svg"
      }
    }
  },
  methods: {
    deleteCompany: async function () {
      var self = this;
      var response = await companyService.deleteCompany(self.companyId);
      if (response.data.successful) {
        self.toggleModal(self.modal.successIcon, "Success", "Company has been deleted");
        setTimeout(function () {
          self.$router.push({
            path: '/companies'
          });
        }, 3000);
      }
      else {
        self.toggleModal(self.modal.errorIcon, "Something went wrong", response.data.errors[0].messages[0]);
      }
    },
    toggleModal: function (imgSrc, title, body) {
      var self = this;
      self.modal.selectedIcon = imgSrc;
      self.modal.title = title;
      self.modal.body = body;

      var overlay_modals = document.querySelector(".overlay");
      const toast_modal = document.getElementById("modal_" + self.companyId);
      toast_modal.classList.add('open');
      overlay_modals.classList.remove("hidden");
      setTimeout(function () {
        toast_modal.classList.remove('open');
        overlay_modals.classList.add("hidden");
      }, 3000);
    }
  },
  props: ["logoBase64Encoded", "company", "plan", "name", "phoneNumber", "companyId", "isDeleted"],
  components: { Loading }
}
</script>
