<template>
  <LoginComponent />
</template>

<script>
import LoginComponent from '@/components/loginComponents/loginComponent.vue';

export default {
  components: { LoginComponent }
}
</script>

<style >

</style>