<template>
  <div class="row_right">
    <DashboardTitleComponent title="New Requests" />
    <ul class="responsive-table table-request">
      <li class="table-header  request-header">
        <div class="col col-1">Company name</div>
        <div class="col col-1">Email</div>
        <div class="col col-1">Phone number</div>
        <div class="col col-1">Received on</div>
      </li>

      <li class="table-row request-row" v-for="request in companyRequests" :key="request.id"
        :class="{ 'marked-table': request.isReadByAdmin }" style="cursor: pointer"
        @click="this.$router.push({ path: `/new-request/${request.id}` })">
        <div class="col col-1 ">
          <span >{{ request.companyName }}</span>
        </div>
        <div class="col col-1">
          <span>{{ request.email }}</span>
        </div>
        <div class="col col-1">
          <span>{{ request.phoneNumber }}</span>
        </div>
        <div class="col col-1">
          <span>{{ request.receivedOn }}</span>
        </div>
      </li>

    </ul>
    <div class="pagination-container">
      <!-- Pagination -->
      <vue-awesome-paginate v-if="showPagination" :total-items="pagination.totalCount" v-model="pagination.page"
        :items-per-page="pagination.size" :max-pages-shown="4" paginationContainerClass="pagination"
        active-page-class="active-page" :hide-prev-next-when-ends="true" :on-click="getCompanyRequests"
        paginateButtonsClass="page" back-button-class="back-btn" next-button-class="next-btn">
        <template #prev-button>
          <span>
            <img src="@/assets/images/left-pagination.svg" height="12" />
          </span>
        </template>
        <template #next-button>
          <span>
            <img src="@/assets/images/right-pagination.svg" height="12" />
          </span>
        </template>
      </vue-awesome-paginate>
    </div>
  </div>
  <loading v-model:active="isLoading" :can-cancel="true" :is-full-page="fullPage" color="#26A28F" />
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import DashboardTitleComponent from '../shared/dashboardTitleComponent.vue';

// services
import companyService from '@/services/companyService';

export default {
  mounted() {
    this.getCompanyRequests();
  },
  components: { DashboardTitleComponent, Loading },
  methods: {
    isPageLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
    getCompanyRequests: async function () {
      var self = this;
      self.isPageLoading();
      var response = await companyService.getCompanyRequests(self.pagination.page, self.pagination.size);

      if (response.data.successful) {
        this.companyRequests = response.data.items;

        if (this.companyRequests.length > 0) {
          this.pagination.totalCount = response.data.totalCount;
          this.showPagination = true;
        } else {
          this.showPagination = false;
        }
      }
    }
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      // Response models
      companyRequests: [],
      // Common
      showPagination: false,
      pagination: {
        page: 1,
        size: 7,
        totalCount: 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>