<template>
  <div class="row_right">
    <DashboardTitleComponent title="Company details" />
    <router-link class="btn-to-companies" to="/companies">
      <img src="@/assets/images/arrow-left.svg" alt="arrow left"> <span>Back</span>
    </router-link>
    <div class="company-info">
      <div class="company-logo">
        <img :src="`data:image/png;base64, ${companyDetails.logoBase64Encoded}`" alt="">
      </div>
      <div class="company-contact">
        <h2 class="company-name">{{ companyDetails.companyName }}</h2>
        <p class="contact-title">Contact Info</p>
        <div class="contacts">
          <ul class="responsive-table contacts-table">
            <li class="table-header contact-data">
              <div class="col col-1">Firstname</div>
              <div class="col col-2">Lastname</div>
              <div class="col col-3">Email</div>
              <div class="col col-4">Phone number</div>
            </li>
            <li class="table-row contact-data">
              <div class="col col-1 "><span>{{ companyDetails.contacteeName.split(" ")[0] }}</span>
              </div>
              <div class="col col-2"><span>{{ companyDetails.contacteeName.split(" ")[1] }}</span>
              </div>
              <div class="col col-3"><span>{{ companyDetails.email }}</span></div>
              <div class="col col-4"><span>{{ companyDetails.phoneNumber }}</span></div>
            </li>
          </ul>
        </div>
        <div class="plan-top">
          <!-- TO DO - later create logos for subscription plans as well -->
          <img :src="`data:image/png;base64, ${companyDetails.subscriptionPlanLogoBase64Encoded}`" alt="">
          <span>{{ companyDetails.subscription ? companyDetails.subscription + ' Plan' : '' }}</span>
        </div>
      </div>
    </div>
    <div class="company-reuqeriment">
      <!-- Driver types -->
      <RequirementItem v-if="companyDetails.vacancy" requirementTitle="Driver type"
        :requirementItems="companyDetails.vacancy.driverTypes.map(x => x.type)" />
      <!-- Experience -->
      <RequirementItem v-if="companyDetails.vacancy" requirementTitle="Experience"
        :requirementItems="[companyDetails.vacancy.experience.value]" />
      <!-- Route types -->
      <RequirementItem v-if="companyDetails.vacancy" requirementTitle="Route type"
        :requirementItems="companyDetails.vacancy.routeTypes.map(x => x.type)" />
      <!-- Freight types -->
      <RequirementItem v-if="companyDetails.vacancy" requirementTitle="Freight type"
        :requirementItems="companyDetails.vacancy.freightTypes.map(x => x.type)" />
      <!-- Endorsements -->
      <RequirementItem v-if="companyDetails.vacancy" requirementTitle="Endorsements"
        :requirementItems="companyDetails.vacancy.endorsements.map(x => x.name)" />
    </div>
  </div>
</template>

<script>
import DashboardTitleComponent from '../shared/dashboardTitleComponent.vue';
import RequirementItem from './inComponent/requirementItem.vue';

// services
import companyService from '@/services/companyService';

export default {
  mounted() {
    this.getCompanyDetails();
  },
  components: { DashboardTitleComponent, RequirementItem },
  methods: {
    getCompanyDetails: async function () {
      var response = await companyService.getCompanyDetails(this.companyId);

      if (response.data.successful) {
        this.companyDetails = response.data.item;
      }
    }
  },
  data() {
    return {
      // Response models
      companyDetails: {
        companyId: 0,
        companyName: '',
        contacteeName: '',
        email: '',
        logoBase64Encoded: '',
        subscriptionPlanLogoBase64Encoded: '',
        phoneNumber: '',
        subscription: '',
        vacancy: {
          driverTypes: [],
          experience: {},
          routeTypes: [],
          freightTypes: [],
          endorsements: [],
          id: 0,
          isCdlRequired: false,
          isFeatured: false,
          offers: [],
          subject: '',
          title: ''
        }
      }
    }
  },
  props: {
    companyId: {
      required: true
    }
  }
}
</script>
