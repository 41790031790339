<template>
  <div class="top_row">
    <h1 class="top_title">{{ title }}</h1>
    <div class="sidebar_right">
      <router-link href="#" class="notification" to="/requests">
        <span class="inner_notification">
          <img src="@/assets/images/notification.svg" alt="notification">
        </span>
        <span class="notification_mark" v-if="anyNewCompanyRequestExists"></span>
      </router-link>
      <a href class="user_bar" data-toggle="modals" data-target="#modals-2" v-if="isAuthenticated()">
        <span class="top_user">

          <img
            :src="this.$store.getters.profilePictureBase64Encoded ? this.$store.getters.profilePictureBase64Encoded : require(`@/assets/images/user-icon.svg`)"
            alt="user">
        </span>
        <div class="user_data">
          <span class="user_name">{{ this.$store.getters.adminEmail }}</span>
          <span class="user_lore">{{ this.$store.getters.adminRole }}</span>
        </div>
      </a>
    </div>
  </div>
  <div class="modals modal_user" id="modals-2">
    <a href="#">
      <img src="@/assets/images/user.svg" alt="">
      <router-link to="/profile">Profile</router-link>
    </a>
    <router-link to="/users">
      <img src="@/assets/images/tool.svg" alt="">
      <span>Users</span>
    </router-link>
  </div>
</template>

<script>

import userService from '@/services/userService';
import companyService from '@/services/companyService';

export default {
  mounted() {
    this.getProfileData();
    this.anyNewCompanyRequest();
  },
  methods: {
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated;
    },
    getProfileData: async function () {
      if (!this.$store.getters.profilePictureBase64Encoded) {
        var response = await userService.getCurrentUserProfileData();

        if (response.data.successful) {
          this.$store.commit('setProfilePicture', {
            profilePictureBase64Encoded: response.data.item.profilePictureBase64Encoded
          });
        }
      }
    },
    anyNewCompanyRequest: async function() {
      var resp = await companyService.anyNewCompanyRequest();
      this.anyNewCompanyRequestExists = resp.data;
    }
  },
  data() {
    return {
      user: {
        userName: "Johnny Depp",
        userRole: "Super Admin"
      },
      anyNewCompanyRequestExists: false
    }
  },
  props: ['title'],
}
</script>

<style lang="scss" scoped>

</style>