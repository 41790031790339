import axios from "axios";
import errorHandler from "@/helpers/errorHandler";

export default {
    getDriverTypes: async function () {
        var instance = axios.create({
            baseURL: window.config.baseApiUrl,
            headers: {
                "Content-Type": "application/json"
            }
        });

        var response = {};

        await instance.get('/api/driverType/get')
            .then(res => {
                response = res;
            })
            .catch(async error => {
                response = await errorHandler.handleRequestError(error);
            });

        return response;
    }
}