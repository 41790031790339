import axios from "axios";
import errorHandler from "@/helpers/errorHandler";
import jwtDecode from "jwt-decode";
import store from "@/store/index";

export default {
  getAuthorizedRequest: async function () {
    const adminAccessToken = store.getters.adminAccessToken;
    return axios.create({
      baseURL: window.config.baseApiUrl,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${adminAccessToken}`,
      },
    });
  },
  loginSuperAdmin: async function (loginRequestModel) {
    var instance = axios.create({
      baseURL: window.config.baseApiUrl,
      headers: {
        "Content-Type": "application/json",
        "role": "SuperAdmin",
      },
    });

    var response = {};

    await instance
      .post("/api/auth/login", loginRequestModel)
      .then((res) => {
        response = res;
      })
      .catch(async (error) => {
        response = await errorHandler.handleRequestError(error);
      });
    return response;
  },
  parseJwtToken: function (jwtToken) {
    return jwtDecode(jwtToken);
  },
  forgotPasword: async function (forgotPasswordRequestDto) {
    var response = {};
    var instance = axios.create({
      baseURL: window.config.baseApiUrl,
      headers: {
        "Content-Type": "application/json",
      },
    });

    await instance
      .post("/api/auth/forgotPassword", forgotPasswordRequestDto)
      .then((res) => {
        response = res;
      })
      .catch(async (error) => {
        response = await errorHandler.handleRequestError(error);
      });
    return response;
  },
  resetPassword: async function (resetPasswordRequestModel) {
    var response = {};
    var instance = axios.create({
      baseURL: window.config.baseApiUrl,
      headers: {
        "Content-Type": "application/json",
      },
    });

    await instance
      .post("/api/auth/resetPassword", resetPasswordRequestModel)
      .then((res) => {
        response = res;
      })
      .catch(async (error) => {
        response = await errorHandler.handleRequestError(error);
      });
    return response;
  },
};
