<template>
  <div class="row_right">
    <DashboardTitleComponent title="Users" />
    <div class="users_bar">
      <div class="search">
        <input type="text" placeholder="Search by name" class="search-field"
          v-model="getUsersRequestModel.firstNameOrLastNameSearchKey" />
        <button type="button" class="search-button" @click="searchUsers">
          <img src="@/assets/images/search.svg">
        </button>
      </div>
      <router-link class="btn-new-admin" to="/create-admin">New admin</router-link>
    </div>
    <div class="users_table">
      <div class="users_table_header">
        <div class="col-1">Full name</div>
        <div class="col-1">Email</div>
        <div class="col-3"></div>
      </div>
      <UserTableComponent v-for="user in users" :key="user.userId" :userId="user.userId" :firstName="user.firstName"
        :lastName="user.lastName" :email="user.email" :is-deleted="user.isDeleted"/>
    </div>

    <!-- Pagination -->
    <div class="pagination-container">
      <vue-awesome-paginate v-if="usersPagination.isAnyUserLoaded" :total-items="usersPagination.totalCount"
        v-model="usersPagination.page" :items-per-page="usersPagination.size" :max-pages-shown="4"
        paginationContainerClass="pagination" active-page-class="active-page" :hide-prev-next-when-ends="true"
        :on-click="getUsers" paginateButtonsClass="page" back-button-class="back-btn" next-button-class="next-btn">
        <template #prev-button>
          <span>
            <img src="@/assets/images/left-pagination.svg" height="12" />
          </span>
        </template>
        <template #next-button>
          <span>
            <img src="@/assets/images/right-pagination.svg" height="12" />
          </span>
        </template>
      </vue-awesome-paginate>
    </div>
  </div>
  <loading v-model:active="isLoading" :can-cancel="true" :is-full-page="fullPage" color="#26A28F" />
</template>

<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
// Components
import DashboardTitleComponent from '../shared/dashboardTitleComponent.vue';
import UserTableComponent from './inComponents/userTableComponent.vue';

// Services
import userService from '@/services/userService';

export default {
  mounted() {
    var self = this;
    addEventListener('keypress', function (e) {
      if (e.key === "Enter") {
        self.searchUsers();
      }
    });
    this.getUsers();
  },
  components: {
    DashboardTitleComponent,
    UserTableComponent,
    Loading
  },
  methods: {
    isPageLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false
      }, 300)
    },
    searchUsers: async function () {
      this.isPageLoading();
      this.usersPagination.page = 1;
      this.getUsers();

    },
    getUsers: async function () {
      var self = this;
      self.isPageLoading();
      var response = await userService.getUsers(self.usersPagination.page, self.usersPagination.size, self.getUsersRequestModel.firstNameOrLastNameSearchKey);

      if (response.data.successful) {
        this.users = response.data.items;
        if (this.users.length > 0) {
          this.usersPagination.totalCount = response.data.totalCount;
          this.usersPagination.isAnyUserLoaded = true;
        } else {
          this.usersPagination.isAnyUserLoaded = false;
        }
      }
    }
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      // Request models
      getUsersRequestModel: {
        firstNameOrLastNameSearchKey: ''
      },
      // Response models
      users: [],
      // Common
      usersPagination: {
        page: 1,
        size: 6,
        totalCount: 0,
        isAnyUserLoaded: false
      }
    }
  }
}
</script>
